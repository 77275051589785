import React, { useCallback, useEffect } from 'react';

import { Checkbox, notification, Switch, Table } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { Manufacturer } from '@models/Manufacturer';
import { useManufacturer } from '@hooks/ManufacturerContext';

const ManufacturerCompanies: React.FC = () => {
  const {
    manufacturersByIntegration,
    loading,
    loadManufacturersByIntegration,
    updateManufacturer,
    currentManufacturer,
    setCurrentManufacturer,
  } = useManufacturer();

  useEffect(() => {
    if (currentManufacturer?.integrationId.integrationId) {
      loadManufacturersByIntegration(currentManufacturer?.integrationId.integrationId);
    }
  }, [currentManufacturer?.integrationId.integrationId]);

  const handleChangeActivity = useCallback(
    (manufacturer: Manufacturer) => {
      const index = manufacturersByIntegration.findIndex(
        (element) => manufacturer.manufacturerId === element.manufacturerId,
      );
      const company: Manufacturer = {
        ...manufacturersByIntegration[index],
        manufacturerIsActive: !manufacturersByIntegration[index].manufacturerIsActive,
      };
      updateManufacturer({
        ...company,
        integrationId: { integrationId: currentManufacturer?.integrationId.integrationId },
      }).then((updatedManufacturer: Manufacturer | null) => {
        if (updatedManufacturer != null) {
          console.log('passei2');
          loadManufacturersByIntegration(currentManufacturer?.integrationId.integrationId);
          notification.success({
            message: 'Sucesso',
            description: `Empresa ${company.manufacturerIsActive ? 'ativada' : 'desativada'} com sucesso`,
          });
        }
      });
    },
    [
      updateManufacturer,
      loadManufacturersByIntegration,
      manufacturersByIntegration,
      currentManufacturer?.integrationId.integrationId,
    ],
  );

  const handleCheckBoxHeadOffice = useCallback(
    async (company: Manufacturer) => {
      const index = manufacturersByIntegration.findIndex(
        (element) => company.manufacturerId === element.manufacturerId,
      );
      if (index > -1) {
        if (manufacturersByIntegration[index].manufacturerIsHeadOffice) {
          return;
        }
        const previousHeadOffice = manufacturersByIntegration.find((element: Manufacturer) => {
          return element.manufacturerIsHeadOffice;
        });
        const newHeadOffice: Manufacturer = manufacturersByIntegration[index];
        if (previousHeadOffice) {
          const result = await Promise.all([
            updateManufacturer({
              ...previousHeadOffice,
              manufacturerIsHeadOffice: false,
              integrationId: { integrationId: currentManufacturer?.integrationId.integrationId },
            }),
            updateManufacturer({
              ...newHeadOffice,
              manufacturerIsHeadOffice: true,
              integrationId: { integrationId: currentManufacturer?.integrationId.integrationId },
            }),
          ]);
          if (result.every((element) => element != null)) {
            notification.success({ message: 'Sucesso', description: 'Matriz trocada com sucesso!' });
            setCurrentManufacturer(result[1]);
            console.log('passei3');
            loadManufacturersByIntegration(currentManufacturer?.integrationId.integrationId);
          }
        }
      } else {
        console.error('Id da matriz não encontrada no array manufacturersByIntegration');
        notification.error({ message: 'Erro', description: 'Erro ao tentar alterar matriz!' });
      }
    },
    [manufacturersByIntegration],
  );

  const columns: ColumnsType<any> = [
    {
      key: 'manufacturerIsActive',
      title: 'Ativo',
      dataIndex: 'manufacturerIsActive',
      render: (value: any, company: Manufacturer, index: number) => (
        <Switch checked={value} onChange={() => handleChangeActivity(company)} />
      ),
    },
    {
      key: 'manufacturerIsHeadOffice',
      title: 'Matriz',
      dataIndex: 'manufacturerIsHeadOffice',
      render: (value: any, company: Manufacturer, index: number) => (
        <Checkbox className="ml-2" checked={value} onChange={() => handleCheckBoxHeadOffice(company)} />
      ),
    },
    {
      key: 'manufacturerId',
      title: 'Id',
      dataIndex: 'manufacturerId',
    },
    {
      key: 'manufacturerFantasyName',
      title: 'Nome Fantasia',
      dataIndex: 'manufacturerFantasyName',
    },
    {
      key: 'manufacturerCnpj',
      title: 'CNPJ',
      dataIndex: 'manufacturerCnpj',
      render: (value: number) => {
        return value?.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      },
    },
    {
      key: 'manufacturerExternalId',
      title: 'Cód. Emp.',
      dataIndex: 'manufacturerExternalId',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={manufacturersByIntegration}
      loading={loading}
      rowKey={(company: Manufacturer) => company.manufacturerId}
    />
  );
};

export default ManufacturerCompanies;
