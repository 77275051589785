import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Row, Col, Input, Table, Button, Modal, notification, Switch, Popconfirm, Checkbox } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import { useCategory } from '@hooks/CategoryContext';
import { Category } from '@models/Category';
import CategoryForm from './components/CategoryForm';
import { ICategoryForm } from './models/ICategoryForm';

const CategoryPage: React.FC = () => {
  type CategoryFormHandleHandle = React.ElementRef<typeof CategoryForm>;
  const categoryFormRef = useRef<CategoryFormHandleHandle>(null);
  const {
    categories,
    loading,
    loadRequestCategory,
    createCategory,
    updateCategory,
  } = useCategory();
  const [searchCategory, setSearchCategory] = useState<string>();
  const [filterCategoryIsActive, setFilterCategoryIsActive] = useState<boolean>();
  const [createCategoryModalIsVisible, setCreateCategoryModalIsVisible] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState<Category>();

  const handleCreateCategory = useCallback((category: ICategoryForm) => {
    try {
      if (!category.categoryId) {
        createCategory({...category, categoryIsActive: true});
      } else {
        updateCategory(category, category);
        setCurrentCategory(undefined);
      }
      setCreateCategoryModalIsVisible(false);
      notification.success({message: 'Sucesso', description: 'Categoria salva com sucesso'});
    } catch(err) {
      console.error('error in handle save category: ', err);
      notification.error({message: 'Erro', description: 'Falha ao salvar a categoria'});
    }
  }, [createCategory, updateCategory]);

  const handleEditCategory = useCallback((category: Category) => {
    setCurrentCategory(category);
    setCreateCategoryModalIsVisible(true);
  }, [currentCategory]);

  const handleToggleCategory = useCallback((category: Category) => {
    category.categoryIsActive = !category.categoryIsActive;
    try {
      updateCategory(category);
    } catch(error) {
      notification.error({message: 'Erro', description: 'Falha ao salvar a categoria'});
    }
  }, [updateCategory]);

  useEffect(() => {
    loadRequestCategory();
  }, []);

  useEffect(() => {
    if (searchCategory !== undefined || filterCategoryIsActive !== undefined) {
      loadRequestCategory(filterCategoryIsActive ? true : undefined, searchCategory);
    }
  }, [searchCategory, filterCategoryIsActive]);

  useEffect(() => {
    if(!createCategoryModalIsVisible && categoryFormRef && categoryFormRef.current) {
      categoryFormRef.current.resetForm();
    }
  }, [createCategoryModalIsVisible, categoryFormRef]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'categoryId',
      sorter: (category: Category , nextCategory: Category) => category.categoryId - nextCategory.categoryId
    },
    {
      title: 'Categoria',
      dataIndex: 'description',
      sorter: (category: Category , nextCategory: Category) => category.description.localeCompare(nextCategory.description),
    },
    {
      title: 'Criado em',
      dataIndex: 'categoryCreatedAt',
      render: (key: string, category: Category): JSX.Element => {
        return (
          <>
            {new Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            }).format(new Date(category.categoryCreatedAt))}
          </>
        );
      }
    },
    {
      title: "Ações",
      dataIndex: 'actions',
      render: (text: any, category: any): JSX.Element => {
        const {key, ...selectedCategory} = category;

        return (
          <>
            <Button onClick={() => {
              handleEditCategory(selectedCategory)

            }} type="link" icon={<FormOutlined />}></Button>

            <Popconfirm
              title={`Tem certeza que deseja ${selectedCategory.categoryIsActive ? 'desativar' : 'ativar'} a categoria ${selectedCategory.categoryId}?`}
              onConfirm={() => handleToggleCategory(selectedCategory)}
              okText="Sim"
              cancelText="Não"
            >
              <Switch checked={selectedCategory.categoryIsActive} className="ml-4"/>
            </Popconfirm>
          </>
        )
      }
    }
  ];

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span="16" className="d-flex justify-space-between">
          <Input.Search allowClear onSearch={(search) => setSearchCategory(search)} placeholder="Busca por nome da categoria" size="large" className="input mr-9"/>
        </Col>
        <Col>
        <Checkbox
          onChange={(checkBoxChangeEvent) => setFilterCategoryIsActive(checkBoxChangeEvent.target.checked)}>
            Filtrar apenas ativos
          </Checkbox>
          <Button onClick={() => setCreateCategoryModalIsVisible(true)} size="large" type="primary" className="ml-4">Nova Categoria</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={loading}
        dataSource={categories?.map((category: Category) => ({ ...category, key: category.categoryId }))} />

      <Modal
        title={currentCategory ? 'Editar Categoria' : 'Nova Categoria'}
        visible={createCategoryModalIsVisible}
        footer={null}
        onCancel={() => {
          setCreateCategoryModalIsVisible(false);
         } }>
          <CategoryForm ref={categoryFormRef} onSubmit={handleCreateCategory} category={currentCategory} />
      </Modal>
    </>
  )
}

export default CategoryPage;
