import React, { createContext, useState, ReactNode, useContext, useCallback } from 'react';

import { Subcategory } from '@models/Subcategory';
import * as SubcategoryService from '@services/subcategory';
import { ISubcategoryForm } from '@pages/SubcategoryPage/models/ISubcategoryForm';
import { responseIsSuccess } from '@services/api';

interface SubcategoryState {
  subcategories: Subcategory[];
  loadRequestSubcategory: Function;
  clearSubcategories: Function;
  createSubcategory: Function;
  updateSubcategory: Function;
  errors: string[];
  clearErrors: Function;
}

interface SubcategoryProviderProps {
  children: ReactNode;
}

export const SubcategoryContext = createContext<SubcategoryState | any>({});

const SubcategoryProvider: React.FC<SubcategoryProviderProps> = ({ children }) => {
  const [subcategories, setSubcategories] = useState<Subcategory[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();

  const loadRequestSubcategory = async (categoryId: number, status: boolean, search = '') => {
    setLoading(true);
    if (categoryId) {
      const { data: receivedSubcategories } = await SubcategoryService.get(categoryId, status, search);
      setSubcategories(receivedSubcategories);
    } else {
      setSubcategories(null);
    }
    setLoading(false);
  };

  const clearSubcategories = () => {
    setSubcategories([]);
  };

  const createSubcategory = useCallback(
    async (subcategory: ISubcategoryForm) => {
      setLoading(true);
      try {
        const { data: newSubcategory } = await SubcategoryService.save(subcategory);
        if (subcategories == null) {
          setSubcategories([newSubcategory]);
        } else {
          setSubcategories(subcategories.concat(newSubcategory));
        }
      } catch (error) {
        console.error('error no context', error);
      } finally {
        setLoading(false);
      }
    },
    [subcategories],
  );

  const updateSubcategory = useCallback(
    async (subcategory: ISubcategoryForm) => {
      setLoading(true);
      try {
        const { status: status, data: updatedSubcategory } = await SubcategoryService.update(subcategory);
        if (!responseIsSuccess(status)) {
          setErrors(updatedSubcategory.message);
        } else {
          setErrors([]);
          if (subcategories != null && subcategories.length != 0) {
            setSubcategories(
              subcategories.map((sub) => {
                if (sub.subCategoryId == updatedSubcategory.subCategoryId) {
                  return updatedSubcategory;
                }
                return sub;
              }),
            );
          } else {
            setSubcategories([updatedSubcategory]);
          }
        }
      } catch (error) {
        console.error('error no context', error);
      }
      setLoading(false);
    },
    [subcategories],
  );

  const clearErrors = () => {
    setErrors([]);
  };

  return (
    <SubcategoryContext.Provider
      value={{
        subcategories,
        loading,
        errors,
        loadRequestSubcategory,
        clearSubcategories,
        createSubcategory,
        updateSubcategory,
        clearErrors,
      }}
    >
      {children}
    </SubcategoryContext.Provider>
  );
};

const useSubcategory = () => {
  const context = useContext(SubcategoryContext);

  return context;
};

export { SubcategoryProvider, useSubcategory };
