import { useIntegration } from '@hooks/IntegrationContext';
import { useIntegrationPermission } from '@hooks/IntegrationPermissionContext';
import { Button, Checkbox, notification, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

interface ManufacturerPermissionProps {}

type PermissionsByIntegrationType = {
  permissionId: string;
  label: string;
  description: string;
  isActive: boolean;
  createdAt?: Date;
};

const ManufacturerPermission: React.FC<ManufacturerPermissionProps> = () => {
  const { loading: loadingIntegration, currentIntegration } = useIntegration();
  const { loading: loadingPermission, permissions, getPermissions, updatePermissions } = useIntegrationPermission();
  const [permissionsByIntegration, setPermissionsByIntegration] = useState<PermissionsByIntegrationType[]>([]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    const permissionsArray = permissions.map(({ permissionId, label, description }) => {
      const currentPermission = currentIntegration?.integrationPermissions.find((perm) => {
        return perm.permissionId === permissionId;
      });

      return {
        permissionId,
        label,
        description,
        createdAt: currentPermission?.createdAt,
        isActive: Boolean(currentPermission),
      };
    });
    setPermissionsByIntegration(permissionsArray);
  }, [permissions, currentIntegration]);

  const formatTitle = (date: any): string => {
    if (!date) {
      return 'Permissão não está ativa';
    }
    const formatedDate = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
    return `Permissão ativa desde ${formatedDate}`;
  };

  const saveEditions = async () => {
    const success = await updatePermissions({
      integrationId: currentIntegration?.integrationId || -1,
      permissions: permissionsByIntegration
        .filter(({ isActive }) => !!isActive)
        .map(({ permissionId }) => permissionId),
    });
    if (success) {
      notification.success({ message: 'Sucesso!', description: 'Salvo com sucesso!' });
    }
  };

  const handleChangeCheckboxes = (permission: PermissionsByIntegrationType) => {
    const index = permissionsByIntegration.findIndex((element) => element.permissionId === permission.permissionId);
    const arrayCopy = [...permissionsByIntegration];
    if (index > -1) {
      arrayCopy[index].isActive = !arrayCopy[index].isActive;
    } else {
      arrayCopy.push({ ...permission, isActive: true });
    }
    setPermissionsByIntegration(arrayCopy);
  };

  const columns: ColumnType<PermissionsByIntegrationType>[] = [
    {
      title: 'Permissão',
      key: 'label',
      dataIndex: 'label',
    },
    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Ativo',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (isActive: boolean, permission: PermissionsByIntegrationType) => {
        return (
          <div title={formatTitle(permission.createdAt)}>
            <Checkbox checked={isActive} onChange={() => handleChangeCheckboxes(permission)} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={permissionsByIntegration}
        loading={loadingIntegration || loadingPermission}
        rowKey={(permissionsByIntegration) => permissionsByIntegration.permissionId}
      />
      <Button className="mt-4" type="primary" htmlType="submit" size="large" onClick={saveEditions} block>
        Salvar
      </Button>
    </>
  );
};

export default ManufacturerPermission;
