import React, { useEffect, useState } from 'react';

import { Button, Checkbox, notification, Table } from 'antd';

import { useBrand } from '@hooks/BrandContext';
import { BrandIntegration } from '@models/BrandIntegration';
import { useIntegration } from '@hooks/IntegrationContext';

interface ManufacturerBrandsProps {}

const ManufacturerBrands: React.FC<ManufacturerBrandsProps> = () => {
  const { brands, loading: loadingBrands, loadRequestBrand } = useBrand();
  const { loading, currentIntegration, updateBrandsIntegrations } = useIntegration();
  const [relationshipBrandsIntegrations, setRelationshipBrandsIntegrations] = useState<BrandIntegration[]>(
    currentIntegration?.brandsIntegrations || [],
  );

  const handleChangeCheckboxes = async (
    brandIntegration: BrandIntegration,
    type: 'integrationIsManufacturer' | 'integrationIsReleased',
  ) => {
    const index = relationshipBrandsIntegrations.findIndex((element) => element.brandId === brandIntegration.brandId);
    const arrayCopy = [...relationshipBrandsIntegrations];
    if (index > -1) {
      arrayCopy[index][type] = !arrayCopy[index][type];
    } else {
      arrayCopy.push({ ...brandIntegration, [type]: !brandIntegration[type] });
    }
    setRelationshipBrandsIntegrations(arrayCopy);
  };

  const saveEditions = async () => {
    const status = await updateBrandsIntegrations({
      ...currentIntegration,
      brandsIntegration: relationshipBrandsIntegrations.filter((element) => {
        if (element.integrationIsManufacturer || element.integrationIsReleased) {
          return element;
        }
      }),
    });
    if (status) {
      notification.success({ message: 'Sucesso!', description: 'Salvo com sucesso!' });
    }
  };

  useEffect(() => {
    loadRequestBrand();
    if (currentIntegration) {
      setRelationshipBrandsIntegrations(currentIntegration.brandsIntegrations);
    } else {
      setRelationshipBrandsIntegrations([]);
    }
  }, [currentIntegration]);

  const columns = [
    {
      key: 'brandId',
      title: 'Id',
      dataIndex: 'brandId',
    },
    {
      key: 'brandName',
      title: 'Nome',
      dataIndex: 'brandName',
    },
    {
      key: 'integrationIsReleased',
      title: 'Liberado',
      dataIndex: 'integrationIsReleased',
      render: (isActive: boolean, brandIntegration: any) => {
        return (
          <Checkbox
            checked={isActive}
            onChange={() => handleChangeCheckboxes(brandIntegration, 'integrationIsReleased')}
          />
        );
      },
    },
    {
      key: 'integrationIsManufacturer',
      title: 'Fabricante',
      dataIndex: 'integrationIsManufacturer',
      render: (isActive: boolean, brandIntegration: any) => {
        return (
          <Checkbox
            checked={isActive}
            onChange={() => handleChangeCheckboxes(brandIntegration, 'integrationIsManufacturer')}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={brands
          .filter((element) => element.brandIsActive)
          .map((element) => {
            const relation = relationshipBrandsIntegrations.find(
              (relationship) => relationship.brandId === element.brandId,
            );
            if (relation) {
              return {
                brandId: element.brandId,
                brandName: element.brandName,
                integrationIsReleased: relation.integrationIsReleased,
                integrationIsManufacturer: relation.integrationIsManufacturer,
              };
            }
            return {
              brandId: element.brandId,
              brandName: element.brandName,
              integrationIsReleased: false,
              integrationIsManufacturer: false,
            };
          })}
        loading={loading || loadingBrands}
        rowKey={(vinculation) => vinculation.brandId}
      />
      <Button className="mt-4" type="primary" htmlType="submit" size="large" onClick={saveEditions} block>
        Salvar
      </Button>
    </>
  );
};

export default ManufacturerBrands;
