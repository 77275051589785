import React, { createContext, useState, ReactNode, useContext, useCallback } from "react";

import { Category } from '@models/Category'
import * as CategoryService from '@services/category';
import { ICategoryForm } from "@pages/CategoryPage/models/ICategoryForm";

interface CategoryState {
  categories: Category[];
  loadRequestCategory: Function;
  createCategory: Function;
  updateCategory: Function;
}

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryContext = createContext<CategoryState|any>({});

const CategoryProvider: React.FC<CategoryProviderProps> = ({ children }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadRequestCategory = useCallback(async(status: boolean, search = '') => {
      setLoading(true);
      const { data: categories } = await CategoryService.get(status, search);
      setLoading(false);
      setCategories(categories);
    }, [categories]);

    const createCategory = useCallback(async (category: ICategoryForm) => {
      setLoading(true);
      try {
          const {data: newCategory}  = await CategoryService.save(category);
          setCategories([...categories, newCategory]);
      } catch (error) {
          console.log('error no context', error);
      }
      setLoading(false);
  }, [categories]);

  const updateCategory = useCallback(async (category: ICategoryForm) => {
      setLoading(true);
      const {data: updatedCategory}  = await CategoryService.update(category);
      if (categories.length != 0) {
          setCategories(categories.map(element => {
              if (element.categoryId == updatedCategory.categoryId) {
                  return updatedCategory;
              }
              return element;
          }));
      } else {
          setCategories([updatedCategory]);
      }
      setLoading(false);
  }, [categories]);

    return (
        <CategoryContext.Provider
            value={{
                categories,
                loading,
                loadRequestCategory,
                createCategory,
                updateCategory,
              }}
        >
            {children}
        </CategoryContext.Provider>
    )
}


const useCategory = () => {
    const context = useContext(CategoryContext);
    return context;
}

export { CategoryProvider, useCategory}
