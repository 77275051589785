import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Row, Col, Input, Table, Button, Modal, notification, Switch, Popconfirm, Checkbox } from 'antd';

import { useParameter } from '@hooks/ParameterContext';
import { Parameter } from '@models/Parameter';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';
import { IParameterForm } from './models/IParameterForm';
import ParameterForm from './components/ParameterForm';
import ParameterDetails from './components/ParameterDetails';

const ParameterPage: React.FC = () => {
  type ParameterFormHandleHandle = React.ElementRef<typeof ParameterForm>;
  const parameterFormRef = useRef<ParameterFormHandleHandle>(null);
  const { parameters, loading, loadRequestParameter, createParameter, updateParameter, total, currentPage } =
    useParameter();
  const [searchParameter, setSearchParameter] = useState<string>();
  const [filterParameterIsActive, setFilterParameterIsActive] = useState<boolean>();
  const [createParameterModalIsVisible, setCreateParameterModalIsVisible] = useState<boolean>(false);
  const [parameterDetailsModalIsVisible, setParameterDetailsModalIsVisible] = useState<boolean>(false);
  const [currentParameter, setCurrentParameter] = useState<Parameter>();

  const handleSaveParameter = useCallback(
    (parameter: IParameterForm) => {
      try {
        if (!parameter.parameterId) {
          createParameter(parameter);
        } else {
          updateParameter(parameter);
          setCurrentParameter(undefined);
        }
        setCreateParameterModalIsVisible(false);
        notification.success({ message: 'Sucesso', description: 'Parâmetro salvo com sucesso' });
      } catch (err) {
        console.error('error in handle save parameter: ', err);
        notification.error({ message: 'Erro', description: 'Falha ao salvar o parâmetro' });
      }
    },
    [createParameter, updateParameter],
  );

  const handleEditParameter = (parameter: Parameter) => {
    setCurrentParameter(parameter);
    setCreateParameterModalIsVisible(true);
  };

  const handleParameterDetails = (parameter: Parameter) => {
    setCurrentParameter(parameter);
    setParameterDetailsModalIsVisible(true);
  };

  const handleToggleParameter = useCallback(
    (parameter: Parameter) => {
      parameter.parameterIsActive = !parameter.parameterIsActive;
      try {
        updateParameter(parameter);
      } catch (error) {
        notification.error({ message: 'Erro', description: 'Falha ao salvar o parâmetro' });
      }
    },
    [updateParameter],
  );

  useEffect(() => {
    loadRequestParameter(0);
  }, []);

  useEffect(() => {
    if (searchParameter !== undefined || filterParameterIsActive !== undefined) {
      loadRequestParameter(0, {
        parameterIsActive: filterParameterIsActive === true ? true : undefined,
        searchParameter,
      });
    }
  }, [searchParameter, filterParameterIsActive]);

  useEffect(() => {
    if (!createParameterModalIsVisible && parameterFormRef && parameterFormRef.current) {
      parameterFormRef.current.resetForm();
    }
  }, [createParameterModalIsVisible, parameterFormRef]);

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      sorter: (parameter: Parameter, nextParameter: Parameter) => parameter.code.localeCompare(nextParameter.code),
    },
    {
      title: 'Desc. Parâmetro',
      dataIndex: 'description',
      sorter: (parameter: Parameter, nextParameter: Parameter) =>
        parameter.description.localeCompare(nextParameter.description),
      ellipsis: true,
    },
    {
      title: 'Desc. Utilidade',
      dataIndex: 'utility',
      sorter: (parameter: Parameter, nextParameter: Parameter) =>
        parameter.utility.localeCompare(nextParameter.utility),
      ellipsis: true,
    },
    {
      title: 'Desc. Funcionalidade',
      dataIndex: 'functionality',
      ellipsis: true,
    },
    {
      title: 'Desc. Variante',
      dataIndex: 'variant',
      ellipsis: true,
    },
    {
      title: 'Desc. Valor',
      dataIndex: 'value',
      ellipsis: true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (text: any, parameter: any): JSX.Element => {
        const { key, ...selectedParameter } = parameter;
        return (
          <>
            <Button type="link" icon={<EyeOutlined />} onClick={() => handleParameterDetails(parameter)}></Button>
            <Button type="link" icon={<FormOutlined />} onClick={() => handleEditParameter(parameter)}></Button>
            <Popconfirm
              title={`Tem certeza que deseja ${
                selectedParameter.parameterIsActive ? 'desativar' : 'ativar'
              } o parâmetro ${selectedParameter.code}?`}
              onConfirm={() => handleToggleParameter(selectedParameter)}
              okText="Sim"
              cancelText="Não"
            >
              <Switch checked={selectedParameter.parameterIsActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleTableChanges = (pagination: any, filters: any, sorter: any) => {
    loadRequestParameter(pagination.current, {
      parameterIsActive: filterParameterIsActive === true ? true : undefined,
      searchParameter,
    });
  };

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span={12} className="d-flex justify-space-between">
          <Input.Search
            allowClear
            onSearch={(search) => setSearchParameter(search)}
            placeholder="Buscar por código do parâmetro"
            size="large"
            className="input mr-9"
          />
        </Col>
        <Col>
          <Checkbox onChange={(checkBoxChangeEvent) => setFilterParameterIsActive(checkBoxChangeEvent.target.checked)}>
            Filtrar apenas ativos
          </Checkbox>
          <Button className="ml-4" size="large" type="primary" onClick={() => setCreateParameterModalIsVisible(true)}>
            Novo Parâmetro
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={loading}
        dataSource={parameters?.map((parameter: Parameter) => ({ ...parameter, key: parameter.parameterId }))}
        pagination={{
          total: total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChanges}
      />

      <Modal
        title={currentParameter ? 'Editar Parâmetro' : 'Novo Parâmetro'}
        visible={createParameterModalIsVisible}
        footer={null}
        width="50%"
        onCancel={() => {
          setCreateParameterModalIsVisible(false);
          setCurrentParameter(undefined);
        }}
      >
        <ParameterForm ref={parameterFormRef} onSubmit={handleSaveParameter} parameter={currentParameter} />
      </Modal>
      <Modal
        title="Detalhes do Parâmetro"
        visible={parameterDetailsModalIsVisible}
        footer={null}
        width="50%"
        onCancel={() => {
          setParameterDetailsModalIsVisible(false);
          setCurrentParameter(undefined);
        }}
      >
        <ParameterDetails parameter={currentParameter} />
      </Modal>
    </>
  );
};

export default ParameterPage;
