import { Link } from '@models/Link';
import { ILinkForm } from '@pages/LinkPage/models/ILinkForm';
import * as LinkService from '@services/link';
import { LinkFilter } from '@services/link';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface LinkState {
  links: Link[];
  total: number;
  currentPage: number;
  loadRequestLink: (
    linkfilter: LinkFilter,
    page?: any,
    pageSize?: any,
    orderBy?: string,
    direction?: 'ASC' | 'DESC',
  ) => Promise<void>;
  createLink: (link: ILinkForm) => Promise<void>;
  updateLink: (link: ILinkForm) => Promise<void>;
  loading: boolean;
}

interface LinkProviderProps {
  children: ReactNode;
}

export const LinkContext = createContext<LinkState>(Object.assign({}));

const LinkProvider: React.FC<LinkProviderProps> = ({ children }) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const loadRequestLink = useCallback(
    async (
      linkfilter: LinkFilter,
      page = 1,
      pageSize = 10,
      orderBy: string = 'linkTitle',
      direction: 'ASC' | 'DESC' = 'ASC',
    ) => {
      try {
        setLoading(true);
        setCurrentPage(page === 0 ? 1 : page);
        const {
          data: { data: receivedLinks, total },
        } = await LinkService.get(linkfilter, page ? page - 1 : 0, pageSize, orderBy, direction);
        setLinks(receivedLinks);
        setTotal(total);
      } catch (e) {
        console.warn('error on link context [loadRequestLink]', e);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [links],
  );

  const createLink = useCallback(
    async (link: ILinkForm) => {
      setLoading(true);
      try {
        const { data: newLink } = await LinkService.save(link);
        return newLink;
      } catch (error) {
        console.warn('error on link context [createLink]', error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [links],
  );

  const updateLink = useCallback(
    async (link: ILinkForm) => {
      setLoading(true);
      try {
        const { data: linkUpdated }: { data: Link } = await LinkService.update(link);
        if (links.findIndex((element) => element.linkId === linkUpdated.linkId) > -1) {
          setLinks(
            links.map((element) => {
              if (element.linkId === linkUpdated.linkId) {
                return linkUpdated;
              }
              return element;
            }),
          );
        }
      } catch (error) {
        console.warn('error on link link context [udpateLink]', error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [links],
  );

  const linkContextProviderValue = {
    links,
    loading,
    total,
    currentPage,
    loadRequestLink,
    createLink,
    updateLink,
  };

  return <LinkContext.Provider value={linkContextProviderValue}>{children}</LinkContext.Provider>;
};

const useLink = () => {
  return useContext(LinkContext);
};

export { LinkProvider, useLink };
