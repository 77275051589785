import CustomTagSelect from '@components/CustomTagSelect';
import { useBrand } from '@hooks/BrandContext';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { Link } from '@models/Link';
import { ILinkForm } from '@pages/LinkPage/models/ILinkForm';
import { Button, Col, DatePicker, Form, Input, notification, Row, Select, Skeleton, Switch } from 'antd';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import moment, { Moment } from 'moment';
import { useLink } from '@hooks/LinkContext';
import { Brand } from '@models/Brand';
import { Manufacturer } from '@models/Manufacturer';
import * as LinkService from '@services/link';

interface LinkFormProps {
  link?: Link;
  onSubmit: Function;
}

interface LinkFormFields {
  linkId?: number;
  linkTitle: string;
  linkDescription: string;
  linkExpiration: [Moment, Moment];
  linkManufacturerActive: boolean;
  linkRepresentativeActive: boolean;
  linkUrl: string;
  brandIds: string[];
  manufacturerIds: string[];
  linkIsActive: boolean;
}

type LinkFormHandle = {
  resetForm: () => void;
};

const LinkForm: React.ForwardRefRenderFunction<LinkFormHandle, LinkFormProps> = ({ link, onSubmit }, ref) => {
  const [form] = Form.useForm<LinkFormFields>();
  const { loading } = useLink();
  const { brands, loading: loadingBrands } = useBrand();
  const { manufacturers, loading: loadingManufacturers } = useManufacturer();
  const [loadingLink, setLoadingLink] = useState<boolean>(false);

  //Constantes
  const divisor = '_&_';

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      form.resetFields();
    },
  }));

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values: LinkFormFields) => {
        const linkForm: ILinkForm = {
          linkId: values?.linkId,
          linkTitle: values.linkTitle,
          linkDescription: values.linkDescription,
          linkUrl: values.linkUrl,
          linkExpirationInitialDate: moment(values.linkExpiration[0]).toDate(),
          linkExpirationFinalDate: moment(values.linkExpiration[1]).toDate(),
          linkManufacturerActive: Boolean(values.linkManufacturerActive),
          linkRepresentativeActive: Boolean(values.linkRepresentativeActive),
          linkIsActive: values.linkIsActive,
          //retira do valor do form apenas o id que vai ser usado
          manufacturerIds: values.manufacturerIds.map((manufacturerId) => +manufacturerId.toString().split(divisor)[0]),
          brandIds: values.brandIds.map((brandId) => +brandId.toString().split(divisor)[0]),
        };
        onSubmit(linkForm);
      })
      .catch((errorInfo) => {
        console.warn(errorInfo);
      });
  }, [onSubmit]);

  useEffect(() => {
    if (link) {
      setLoadingLink(true);
      LinkService.get({ linkId: link?.linkId })
        .then(
          ({
            data: {
              data: [fetchedLink],
            },
          }: {
            data: { data: Link[] };
          }) => {
            const brandIds = fetchedLink.linkManufacturerBrands.map((lmb) => +lmb.brandId);
            const manufacturerIds = fetchedLink.linkManufacturerBrands.map((lmb) => +lmb.manufacturerId);

            form.setFieldsValue({
              linkId: fetchedLink.linkId,
              linkDescription: fetchedLink.linkDescription,
              linkExpiration: [
                moment(fetchedLink.linkExpirationInitialDate),
                moment(fetchedLink.linkExpirationFinalDate),
              ],
              linkTitle: fetchedLink.linkTitle,
              linkManufacturerActive: Boolean(fetchedLink.linkManufacturerActive),
              linkRepresentativeActive: Boolean(fetchedLink.linkRepresentativeActive),
              linkIsActive: Boolean(fetchedLink.linkIsActive),
              linkUrl: fetchedLink.linkUrl,
              brandIds: brandIds
                //remove os itens repetidos dos arrays
                .filter((x: number, i) => brandIds.indexOf(x) === i)
                //monta o value usando o id pra ficar compatível com o value do <Select />
                .map((brandId) => {
                  return formatValueOfBrand(brands[brands.findIndex((b) => b.brandId == brandId)]);
                }),
              manufacturerIds: manufacturerIds
                .filter((x: number, i) => manufacturerIds.indexOf(x) === i)
                .map((manufacturerId) => {
                  return formatValueOfManufacturer(
                    manufacturers[manufacturers.findIndex((m) => m.integrationId.integrationId == manufacturerId)],
                  );
                }),
            });
          },
        )
        .catch((e) => {
          console.warn(e);
          notification.error({ message: 'Erro ao buscar link!' });
          throw e;
        })
        .finally(() => {
          setLoadingLink(false);
        });
    } else {
      form.resetFields();
      //Valores default do form
      form.setFieldsValue({
        linkIsActive: true,
      });
    }
  }, [form, link]);

  const formatValueOfBrand = ({ brandId, brandName }: Brand): string => {
    return `${brandId}${divisor}${brandName}`;
  };

  const formatValueOfManufacturer = ({
    integrationId: { integrationId },
    manufacturerFantasyName,
    manufacturerName,
  }: Manufacturer): string => {
    return `${integrationId}${divisor}${manufacturerFantasyName}${divisor}${manufacturerName}`;
  };

  const customTagRender = (props: any) => <CustomTagSelect data={props} />;

  const styleSwitchLabel: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };
  return loadingLink ? (
    <>
      <Skeleton />
      <br />
      <Skeleton />
      <br />
      <Skeleton />
      <br />
      <Skeleton.Button block />
    </>
  ) : (
    <Form layout="vertical" form={form} key={link?.linkId} onFinish={handleFormSubmit} autoComplete="off">
      <Form.Item name="linkId" hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="linkIsActive" hidden={true}>
        <Input type="hidden" />
      </Form.Item>
      <Row align="middle" justify="space-between" className="my-0">
        <Col span={24}>
          <Form.Item
            name="linkTitle"
            label="Título"
            className="mb-2"
            rules={[{ required: true, message: 'Informe o título do link' }]}
          >
            <Input placeholder="Título" size="large" className="input" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="linkDescription"
            label="Descrição"
            className="mb-2"
            rules={[{ required: true, message: 'Informe a descrição' }]}
          >
            <Input placeholder="Descrição" size="large" className="input" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="linkExpiration"
            label="Validade"
            className="mb-2"
            rules={[{ required: true, message: 'Informe a validade' }]}
          >
            <DatePicker.RangePicker style={{ width: '100%' }} size="large" format="DD/MM/YYYY"></DatePicker.RangePicker>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="linkUrl" label="URL" className="mb-2" rules={[{ required: true, message: 'Informe a url' }]}>
            <Input placeholder="URL" size="large" className="input" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="manufacturerIds"
            label="Fabricante"
            className="mb-2"
            rules={[{ required: true, message: 'Informe ao menos um fabricante' }]}
          >
            <Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              size="large"
              className="input"
              placeholder="Selecione os fabricantes"
              options={manufacturers.map((manufacturer) => ({
                label: `${manufacturer.manufacturerFantasyName}`,
                value: formatValueOfManufacturer(manufacturer),
              }))}
              loading={loadingManufacturers}
              maxTagCount="responsive"
              tagRender={customTagRender}
              showArrow
              allowClear
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="brandIds"
            label="Marca"
            className="mb-2"
            rules={[{ required: true, message: 'Informe ao menos uma marca' }]}
          >
            <Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              size="large"
              className="input"
              placeholder="Selecione as marcas"
              options={brands.map((brand) => ({
                label: brand.brandName,
                value: formatValueOfBrand(brand),
              }))}
              loading={loadingBrands}
              maxTagCount="responsive"
              tagRender={customTagRender}
              showArrow
              allowClear
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <Form.Item name="linkManufacturerActive" valuePropName="checked" className="mb-2 mt-4">
            <Switch className="ml-4" />
          </Form.Item>
        </Col>
        <Col span={8} style={styleSwitchLabel}>
          Fabricante(s)
        </Col>
        <Col span={4}>
          <Form.Item name="linkRepresentativeActive" valuePropName="checked" className="mb-2 mt-4">
            <Switch className="ml-4" />
          </Form.Item>
        </Col>
        <Col span={8} style={styleSwitchLabel}>
          Representante(s)
        </Col>
      </Row>
      <Button className="mt-4" type="primary" htmlType="submit" size="large" block loading={loading || loadingLink}>
        Salvar
      </Button>
    </Form>
  );
};

export default React.forwardRef(LinkForm);
