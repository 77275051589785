import React, { useCallback, useEffect, useImperativeHandle } from 'react';

import { Button, Checkbox, Col, Form, Input, Row } from 'antd';

import { Parameter } from '@models/Parameter';
import { IParameterForm } from '@pages/ParameterPage/models/IParameterForm';

interface ParameterFormProps {
  parameter?: Parameter;
  onSubmit: Function;
}

type ParameterFormHandle = {
  resetForm: () => void;
};

const ParameterForm: React.ForwardRefRenderFunction<ParameterFormHandle, ParameterFormProps> = (
  { parameter, onSubmit },
  ref,
) => {
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    resetForm: () => {
      form.resetFields();
    },
  }));
  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values: IParameterForm) => {
        onSubmit({...values, hasVariant: !!values.hasVariant});
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  }, [onSubmit]);

  useEffect(() => {
    if (parameter) {
      form.setFields([
        { name: 'parameterId', value: parameter.parameterId },
        { name: 'code', value: parameter.code },
        { name: 'description', value: parameter.description },
        { name: 'utility', value: parameter.utility },
        { name: 'functionality', value: parameter.functionality },
        { name: 'variant', value: parameter.variant },
        { name: 'value', value: parameter.value },
        { name: 'parameterIsActive', value: parameter.parameterIsActive },
        { name: 'hasVariant', value: parameter.hasVariant },
      ]);
    } else {
      form.resetFields();
      form.setFieldsValue({
        parameterIsActive: true,
        hasVariant: false
      });
    }
  }, [form, parameter]);

  return (
    <Form layout="vertical" form={form} onFinish={handleFormSubmit} autoComplete="off">
      <Row align="middle" justify="space-between" className="my-0">
        <Col span={24}>
          <Form.Item name="parameterId" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="parameterIsActive" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="code"
            label="Código do Parâmetro"
            rules={[{ required: true, message: 'Informe o código do parâmetro' }]}
          >
            <Input placeholder="Utilize o acrônimo como código do parâmetro" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="description"
            label="Descrição do Parâmetro"
            rules={[{ required: true, message: 'Informe a descrição do parâmetro' }]}
          >
            <Input.TextArea showCount maxLength={500} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="utility"
            label="Descrição da Utilidade"
            rules={[{ required: true, message: 'Informe a descrição da utilidade' }]}
          >
            <Input.TextArea showCount maxLength={500} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="functionality"
            label="Descrição da Funcionalidade"
            rules={[{ required: true, message: 'Informe a descrição da funcionalidade' }]}
          >
            <Input.TextArea showCount maxLength={500} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="variant"
            label="Descrição da Variante"
            rules={[{ required: true, message: 'Informe a descrição da variante' }]}
          >
            <Input.TextArea showCount maxLength={500} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="value"
            label="Descrição do Valor"
            rules={[{ required: true, message: 'Informe a descrição do valor' }]}
          >
            <Input.TextArea showCount maxLength={500} />
          </Form.Item>
        </Col>
        <Col span={24} className="d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Form.Item name="hasVariant" valuePropName="checked">
            <Checkbox>Possui Variante?</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Button className="mt-4" type="primary" htmlType="submit" size="large" block>
        Salvar
      </Button>
    </Form>
  );
};

export default React.forwardRef(ParameterForm);
