export const states = (country: 'BR'): { description: string; acronym: string }[] => {
  switch (country) {
    case 'BR':
      return [
        { description: 'Acre', acronym: 'AC' },
        { description: 'Alagoas', acronym: 'AL' },
        { description: 'Amapá', acronym: 'AP' },
        { description: 'Amazonas', acronym: 'AM' },
        { description: 'Bahia', acronym: 'BA' },
        { description: 'Ceará', acronym: 'CE' },
        { description: 'Distrito Federal', acronym: 'DF' },
        { description: 'Espírito Santo', acronym: 'ES' },
        { description: 'Goias', acronym: 'GO' },
        { description: 'Maranhão', acronym: 'MA' },
        { description: 'Mato Grosso', acronym: 'MT' },
        { description: 'Mato Grosso do  Sul', acronym: 'MS' },
        { description: 'Minas Gerais', acronym: 'MG' },
        { description: 'Pará', acronym: 'PA' },
        { description: 'Paraíba', acronym: 'PB' },
        { description: 'Paraná', acronym: 'PR' },
        { description: 'Pernambuco', acronym: 'PE' },
        { description: 'Piauí', acronym: 'PI' },
        { description: 'Rio Grande do Norte', acronym: 'RN' },
        { description: 'Rio Grande do Sul', acronym: 'RS' },
        { description: 'Rio de Janeiro', acronym: 'RJ' },
        { description: 'Rondônia', acronym: 'RO' },
        { description: 'Roraíma', acronym: 'RR' },
        { description: 'Santa Catarina', acronym: '	SC' },
        { description: 'São Paulo', acronym: 'SP' },
        { description: 'Sergipe', acronym: 'SE' },
        { description: 'Tocantis', acronym: 'TO' },
        { description: 'Fora do país', acronym: 'FP' },
        { description: 'Bruxelas', acronym: 'BE' },
      ];
    default:
      return [{ description: 'Country is not avaiable!', acronym: 'Empty' }];
  }
};
