import React, { useCallback, useEffect } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import { Category } from '@models/Category';
import { ISubcategoryForm } from '@pages/SubcategoryPage/models/ISubcategoryForm';
import { Subcategory } from '@models/Subcategory';

interface SubcategoryFormProps {
  subcategory?: Subcategory;
  category: Category | undefined;
  onSubmit: Function;
}

const SubcategoryForm: React.FC<SubcategoryFormProps> = ({ subcategory, category, onSubmit }) => {
  const [form] = Form.useForm();
  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values: ISubcategoryForm) => {
        onSubmit(values);
        form.resetFields();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  }, [onSubmit]);

  useEffect(() => {
    if (subcategory) {
      form.setFields([
        { name: 'subCategoryId', value: subcategory.subCategoryId },
        { name: 'description', value: subcategory.description },
        { name: 'subCategoryIsActive', value: subcategory.subCategoryIsActive },
      ]);
    } else {
      form.resetFields();
      form.setFieldsValue({
        subCategoryIsActive: true,
      });
    }
    form.setFields([{ name: 'categoryId', value: category?.categoryId }]);
  }, [form]);

  return (
    <Form layout="vertical" form={form} onFinish={handleFormSubmit} autoComplete="off">
      <Row align="middle" justify="space-between" className="my-0">
        <Col span={24}>
          <h2>Categoria: {category?.description}</h2>
        </Col>
        <Col span={24}>
          <Form.Item name="categoryId" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="subCategoryId" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="subCategoryIsActive" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Nome da subcategoria"
            className="mb-2"
            rules={[{ required: true, message: 'Informe o nome da subcategoria' }]}
          >
            <Input placeholder="Nome da subcategoria" size="large" className="input" />
          </Form.Item>
        </Col>
      </Row>

      <Button className="mt-4" type="primary" htmlType="submit" size="large" block>
        Salvar
      </Button>
    </Form>
  );
};

export default SubcategoryForm;
