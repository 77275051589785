import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

import { ReactComponent as ModaVestIcon } from '@assets/icons/modavest.svg';

const Loading: React.FC = () => {
    return (
        <>
            <Row align="middle" style={{ height: '100vh' }}>
                <Col span={24} className="d-flex align-center justify-center" style={{ flexDirection: 'column' }}>
                    <ModaVestIcon className="mb-6" width="40" height="40" />
                    <div className="mb-4" style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <LoadingOutlined type="play-circle-o" style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '2em' }} />
                    </div>
                    <p>Carregando...</p>
                </Col>
            </Row>
        </>
    );
}

export default Loading;