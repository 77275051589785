import { BrandIntegration } from '@models/BrandIntegration';
import { IIntegrationForm } from '@pages/ManufacturerPage/components/ManufacturerIntegration/models/IIntegrationForm';
import { AxiosResponse } from 'axios';

import * as api from './api';

const end_point = 'integrations';

export function get(integrationId?: number): Promise<AxiosResponse<any>> {
  if (integrationId) {
    return api.get([end_point, integrationId].join('/'));
  }
  return api.get(end_point);
}

export function save(integration: IIntegrationForm): Promise<AxiosResponse<any>> {
  return api.post(end_point, integration).catch((error) => {
    console.log('no save', error, error.response);
    return Promise.resolve(error.response);
  });
}

export function update(integration: IIntegrationForm): Promise<AxiosResponse<any>> {
  return api.put(`${end_point}/${integration.integrationId}`, integration).catch((error) => {
    console.log('no update', error, error.response);
    return Promise.resolve(error.response);
  });
}

export function verifyCredentials(integration: IIntegrationForm): Promise<AxiosResponse<any>> {
  return api.post(`${end_point}/verifycredentials`, integration).catch((error) => {
    console.log('no verifyCredentials', error, error.response);
    return Promise.resolve(error.response);
  });
}

export function updateBrandsVinculation({
  integrationId,
  brandsIntegration,
}: {
  integrationId: number;
  brandsIntegration: BrandIntegration[];
}): Promise<AxiosResponse<any>> {
  return api.put([end_point, 'brands', integrationId].join('/'), brandsIntegration).catch((error) => {
    console.log('no updateBrandsVinculation', error, error.response);
    return Promise.resolve(error.response);
  });
}
