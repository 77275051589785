import React, { useCallback, useEffect, useImperativeHandle } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import { Category } from '@models/Category';

interface ICreateCategoryData {
  categoryId?: number;
  description: string;
  categoryIsActive: boolean;
}

interface CategoryFormProps {
  category?: Category;
  onSubmit: Function;
}

type CategoryFormHandle = {
  resetForm: () => void,
}

const CategoryForm: React.ForwardRefRenderFunction<CategoryFormHandle, CategoryFormProps> = ({ category, onSubmit }, ref) => {
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    resetForm: () => {
      form.resetFields();
    }
  }));

  const handleFormSubmit = useCallback(() => {
    try {
      form
        .validateFields()
        .then((values: ICreateCategoryData) => {
          onSubmit(values);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } catch (err) {
      console.log(err);
    }
  }, [onSubmit]);

  useEffect(() => {
    if (category) {
      form.setFields([
        { name: 'categoryId', value: category.categoryId },
        { name: 'description', value: category.description },
        { name: 'categoryIsActive', value: category.categoryIsActive },
      ]);
    } else {
      form.resetFields();
      form.setFieldsValue({
        categoryIsActive: true,
      });
    }
  }, [form, category]);

  return (
    <Form layout="vertical" form={form} key={category?.categoryId} onFinish={handleFormSubmit} autoComplete="off">
      <Row align="middle" justify="space-between" className="my-0">
        <Col span={24}>
          <Form.Item name="categoryId" hidden={true}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item name="categoryIsActive" hidden={true}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Nome da categoria"
            className="mb-2"
            rules={[{ required: true, message: 'Informe o nome da categoria' }]}
          >
            <Input placeholder="Nome da categoria" maxLength={500} size="large" className="input" />
          </Form.Item>
        </Col>
      </Row>

      <Button className="mt-4" type="primary" htmlType="submit" size="large" block>
        Salvar
      </Button>
    </Form>
  );
};

export default React.forwardRef(CategoryForm);
