import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Avatar from 'react-avatar';

import './styles.css';
import { Container, Header, Content, Footer } from './styles';
import ModaVestBrand from '@assets/svg/modavest-brand.svg';
import { ReactComponent as ModaVestIcon } from '@assets/icons/modavest.svg';
import { ReactComponent as CompanyIcon } from '@assets/icons/companies.svg';
import { ReactComponent as BrandIcon } from '@assets/icons/brands.svg';
import { ReactComponent as CategoryIcon } from '@assets/icons/categories.svg';
import { ReactComponent as SubcategoryIcon } from '@assets/icons/subcategories.svg';
import { ReactComponent as ParameterIcon } from '@assets/icons/parameters.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import ManufacturerPage from '@pages/ManufacturerPage';
import BrandPage from '@pages/BrandPage';
import CategoryPage from '@pages/CategoryPage';
import ParameterPage from '@pages/ParameterPage';
import SubcategoryPage from '@pages/SubcategoryPage';
import LinkPage from '@pages/LinkPage';
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';

const { Sider } = Layout;

const MainPage: React.FC = () => {
  const [user, setUser] = useState<KeycloakProfile>();
  const { path, url } = useRouteMatch();
  const { keycloak } = useKeycloak();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const handleSignout = useCallback(() => {
    keycloak?.logout();
  }, []);
  const openUrl = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    if (keycloak && !keycloak.hasRealmRole('ans')) {
      handleSignout();
    }

    if (keycloak?.authenticated) {
      keycloak.loadUserProfile().then((profile: KeycloakProfile) => {
        setUser(profile);
      });
      // TODO: use token from somewhere else
      localStorage.setItem('token', keycloak.token ? keycloak.token : '');
    }
  }, [keycloak]);

  return (
    <Container>
      <Sider collapsed={collapsed} theme="dark">
        {collapsed ? (
          <ModaVestIcon className="ml-6 my-4" width="40" height="40" />
        ) : (
          <img className="ml-6 my-4" src={ModaVestBrand} title="ModaVest" alt="logotipo-modavest" />
        )}
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item onClick={() => openUrl(`${url}/fabricantes`)} key="1" icon={<CompanyIcon />}>
            Fabricantes
          </Menu.Item>
          <Menu.Item onClick={() => openUrl(`${url}/marcas`)} key="2" icon={<BrandIcon />}>
            Marcas
          </Menu.Item>
          <Menu.Item onClick={() => openUrl(`${url}/categorias`)} key="3" icon={<CategoryIcon />}>
            Categorias
          </Menu.Item>
          <Menu.Item
            onClick={() => openUrl(`${url}/subcategorias`)}
            key="4"
            icon={<SubcategoryIcon height={23} width={23} fill="#fff" />}
          >
            Subcategorias
          </Menu.Item>
          <Menu.Item onClick={() => openUrl(`${url}/parametros`)} key="5" icon={<ParameterIcon />}>
            Parâmetros
          </Menu.Item>
          <Menu.Item onClick={() => openUrl(`${url}/links`)} key="6" icon={<LinkIcon />}>
            Links
          </Menu.Item>
          <Menu.Item
            key="7"
            className="position-absolute bottom-0 w-100 d-flex align-center pl-6 pr-0"
            onClick={handleSignout}
            icon={<LogoutIcon width="22" height="22" fill="white" />}
          >
            Sair
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="d-flex justify-space-between align-center">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <div className="d-flex align-center">
            <p className="mr-4 mt-4">
              Olá <strong>{user?.firstName}</strong>
            </p>
            <Avatar name={user?.firstName} size="35" round="100%" />
          </div>
        </Header>
        <Content className="py-4 px-8 overflow-auto">
          <Switch>
            <Route exact path={`${path}/fabricantes`}>
              <ManufacturerPage />
            </Route>
            <Route exact path={`${path}/marcas`}>
              <BrandPage />
            </Route>
            <Route exact path={`${path}/categorias`}>
              <CategoryPage />
            </Route>
            <Route exact path={`${path}/subcategorias`}>
              <SubcategoryPage />
            </Route>
            <Route exact path={`${path}/parametros`}>
              <ParameterPage />
            </Route>
            <Route exact path={`${path}/links`}>
              <LinkPage />
            </Route>
            <Redirect from="*" exact to={`${path}/fabricantes`} />
          </Switch>
        </Content>
        <Footer className="text-center">
          Copyright © {new Date().getFullYear()} . ANS Software. Todos os direitos reservados
        </Footer>
      </Layout>
    </Container>
  );
};

export default MainPage;
