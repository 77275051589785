import { Subcategory } from '@models/Subcategory';
import { Response } from '@models/Response';
import * as api from './api';
import { ISubcategoryForm } from '@pages/SubcategoryPage/models/ISubcategoryForm';

export function get(categoryId: number, status?: boolean, search: string = ''): Promise<Response<Subcategory>> {
  let url: string = `categories/${categoryId}/subcategories`;
  if (search.length > 0) {
    url += `?description=${search}`;
  }
  if (status) {
    url += url.includes('?') ? '&' : '?';
    url += 'subCategoryIsActive=true';
  }
  return api.get(url);
}

export function save(subcategory: ISubcategoryForm): Promise<any> {
  const url = `categories/${subcategory.categoryId}/subcategories`;
  return api.post(url, {
    description: subcategory.description,
    subCategoryIsActive: subcategory.subCategoryIsActive,
  });
}

export function update(subcategory: ISubcategoryForm): Promise<any> {
  const url = `categories/subcategories/${subcategory.subCategoryId}`;
  return api
    .put(url, {
      description: subcategory.description,
      subCategoryIsActive: subcategory.subCategoryIsActive,
    })
    .catch((error) => {
      console.log('no update', error, error.response);
      return Promise.resolve(error.response);
    });
}
