import { ILinkForm } from '@pages/LinkPage/models/ILinkForm';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import * as api from './api';

export interface LinkFilter {
  linkId?: number;
  linkTitle?: string;
  linkDescription?: string;
  linkExpirationInitialDateInitial?: Date;
  linkExpirationInitialDateFinal?: Date;
  linkExpirationFinalDateInitial?: Date;
  linkExpirationFinalDateFinal?: Date;
  linkUrl?: string;
  linkManufacturerActive?: boolean;
  linkRepresentativeActive?: boolean;
  linkIsActive?: boolean;
  linkCreatedAtInitial?: Date;
  linkCreatedAtFinal?: Date;
  linkUpdatedAtInitial?: Date;
  linkUpdatedAtFinal?: Date;
  manufacturerIds?: number[];
  brandIds?: number[];
}

export function get(
  linkFilter: LinkFilter,
  page = 0,
  pageSize = 10,
  orderBy: string = '',
  direction: 'ASC' | 'DESC' = 'ASC',
): Promise<AxiosResponse> {
  let url: string = `links?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&direction=${direction}`;

  //Filtra apenas com valors não nulos
  Object.keys(linkFilter || {}).forEach((propertyName) => {
    const linkAsAny: any = linkFilter;
    const propertyValue = linkAsAny[propertyName];
    if (Array.isArray(propertyValue)) {
      propertyValue.forEach((property) => {
        if (property != null) {
          url += `&${propertyName}=${property}`;
        }
      });
    } else if (propertyValue instanceof Date) {
      url += `&${propertyName}=${moment(propertyValue).format('YYYY-MM-DD')}`;
    } else if (propertyValue != null) {
      url += `&${propertyName}=${propertyValue}`;
    }
  });

  return api.get(url);
}

export function save(link: ILinkForm) {
  return api.post('links', link);
}

export function update(link: ILinkForm) {
  return api.put(`links/${link.linkId}`, link);
}
