import Keycloack from 'keycloak-js';

const keycloakConfig = JSON.parse(
  process.env.REACT_APP_ANS_KEYCLOAK_JSON ? process.env.REACT_APP_ANS_KEYCLOAK_JSON : '',
);
export const keycloak = Keycloack({
  url: keycloakConfig['auth-server-url'],
  realm: keycloakConfig['realm'],
  clientId: keycloakConfig['resource'],
});

export const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
};
