import React, { useCallback, useEffect, useState } from 'react';

import { Col, Divider, Row, Spin } from 'antd';

import { Address } from '@models/Address';
import { useManufacturer } from '@hooks/ManufacturerContext';

const ManufacturerAddress: React.FC = () => {
  const { currentManufacturer, getAddressByManufacturerId, loading } = useManufacturer();
  const [address, setAddress] = useState<Address[] | null>();

  const getAddress = useCallback(async () => {
    if (currentManufacturer) {
      const receivedAddress = await getAddressByManufacturerId(currentManufacturer.manufacturerId);
      setAddress(receivedAddress);
    }
  }, [currentManufacturer]);

  useEffect(() => {
    getAddress();
  }, [currentManufacturer]);

  return (
    <Spin spinning={loading}>
      {address && address.length < 1 && <h3>Não há endereços para essa fabricante</h3>}
      {address &&
        address.map((element) => {
          return (
            <Row key={element.addressId}>
              <Col span={8}>
                <strong>Tipo de endereço: </strong>
                {element?.addressType}
              </Col>
              <Col span={8}>
                <strong>Tipo de logradouro: </strong>
                {element?.addressPublicPlace}
              </Col>
              <Col span={8}>
                <strong>Nome do logradouro: </strong>
                {element?.addressName}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Número: </strong>
                {element?.addressNumber}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Complemento: </strong>
                {element?.addressComplement}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Bairro: </strong>
                {element?.addressNeighborhood}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Cidade: </strong>
                {element?.addressCityName}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Estado: </strong>
                {element?.addressUf}
              </Col>
              <Col span={8} className="mt-4">
                <strong>CEP: </strong>
                {element?.addressCep}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Caixa Postal: </strong>
                {element?.addressPostOfficeBox}
              </Col>
              <Col span={8} className="mt-4">
                <strong>Referência: </strong>
                {element?.addressReference}
              </Col>
              <Divider />
            </Row>
          );
        })}
    </Spin>
  );
};
export default ManufacturerAddress;
