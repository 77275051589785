import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
interface Response {
  data: {
    user: {
      name: string;
      email: string;
    };
    token: string;
  };
}

const baseUrl: string | undefined = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    // TODO: use token from somewhere else
    request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function get(path: string, params?: any): Promise<AxiosResponse<any>> {
  return axios.get(`${baseUrl}/${path}`, { params });
}

export function post(path: string, body: any): Promise<AxiosResponse<any>> {
  return axios.post(`${baseUrl}/${path}`, body);
}

export function postWithConfig(path: string, body: any, config: any): Promise<any> {
  return axios.post(`${baseUrl}/${path}`, body, config);
}

export function put(path: string, body: any): Promise<AxiosResponse<any>> {
  return axios.put(`${baseUrl}/${path}`, body);
}

export function responseIsSuccess(status: number) {
  return status >= 200 && status < 300;
}

export function postLogin(text: string, user: object): Promise<Response> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          token: '91j893h281h9nf98fnf2309jd09jkkd0as98238j9fr8j98f9j8f298r829r-f',
          user: {
            name: 'Admin ANS',
            email: 'admin@ans.com.br',
          },
        },
      });
    }, 2000);
  });
}

export const defaults = {
  headers: {
    Authorization: '',
  },
};
