import React, { createContext, useState, ReactNode, useContext, useCallback } from 'react';

import { Parameter } from '@models/Parameter';
import * as ParameterService from '@services/parameter';
import { IParameterForm } from '@pages/ParameterPage/models/IParameterForm';

interface ParameterState {
  parameters: Parameter[];
  loadRequestParameter: Function;
  createParameter: Function;
  updateParameter: Function;
}

interface ParameterProviderProps {
  children: ReactNode;
}

export const ParameterContext = createContext<ParameterState | any>({});

const ParameterProvider: React.FC<ParameterProviderProps> = ({ children }) => {
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const loadRequestParameter = useCallback(
    async (page: number, filters: any) => {
      setLoading(true);
      setCurrentPage(page === 0 ? 1 : page);
      try {
        const { data: _receivedParameters } = await ParameterService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });

        setParameters(_receivedParameters.data);
        setTotal(_receivedParameters.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [parameters],
  );

  const createParameter = useCallback(
    async (parameter: IParameterForm) => {
      setLoading(true);
      try {
        const { data: newParameter } = await ParameterService.save(parameter);
        if (parameters.length == 0) {
          setParameters([newParameter]);
        } else {
          setParameters(parameters.concat(newParameter));
        }
      } catch (error) {
        console.log('error no context', error);
      }
      setLoading(false);
    },
    [parameters],
  );

  const updateParameter = useCallback(
    async (parameter: IParameterForm) => {
      setLoading(true);
      const { data: updatedParameter } = await ParameterService.update(parameter);
      if (parameters.length != 0) {
        setParameters(
          parameters.map((element) => {
            if (element.parameterId == updatedParameter.parameterId) {
              return updatedParameter;
            }
            return element;
          }),
        );
      } else {
        setParameters([updatedParameter]);
      }
      setLoading(false);
    },
    [parameters],
  );

  return (
    <ParameterContext.Provider
      value={{
        parameters,
        loading,
        currentPage,
        total,
        loadRequestParameter,
        createParameter,
        updateParameter,
      }}
    >
      {children}
    </ParameterContext.Provider>
  );
};

const useParameter = () => {
  const context = useContext(ParameterContext);

  return context;
};

export { ParameterProvider, useParameter };
