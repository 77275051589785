import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { Button, Col, Form, Input, Row, Switch, Upload, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Avatar from 'react-avatar';

import { Brand } from '@models/Brand';
import { IBrandForm } from '@pages/BrandPage/models/IBrandForm';
import * as BrandService from '@services/brand';

interface BrandFormProps {
  brand?: Brand,
  onSubmit: Function,
}

type BrandFormHandle = {
  resetForm: () => void,
}

const BrandForm: React.ForwardRefRenderFunction<BrandFormHandle, BrandFormProps> = ({ brand, onSubmit }, ref) => {
  const [form] = Form.useForm();
  const [brandPhotoUrl, setBrandPhotoUrl] = useState<string>('');
  const [loadingBrandUrl, setLoadingBrandUrl] = useState<boolean>(false);
  useImperativeHandle(ref, () => ({
    resetForm: () => {
      form.resetFields();
      setBrandPhotoUrl('');
    }
  }));
  const handleFormSubmit = useCallback(() => {
		form.validateFields()
		.then((values: IBrandForm) => {
			onSubmit(values);
		})
		.catch((errorInfo) => { console.log(errorInfo) });
	}, [onSubmit]);

  useEffect(() => {
    if (brand) {
      form.setFields([
        { name: 'brandId', value: brand.brandId },
        { name: 'brandName', value: brand.brandName },
        { name: 'brandPhotoUrl', value: brand.brandPhotoUrl},
        { name: 'brandIsActive', value: brand.brandIsActive },
      ]);
      if (brand.brandPhotoUrl) {
        setBrandPhotoUrl(brand.brandPhotoUrl);
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        brandIsActive: true,
      });
    }
  }, [form, brand]);

  function beforeUpload(file: File) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'Formato inválido', description: 'Apenas imagens JPG/PNG são aceitas!' });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({ message: 'Arquivo muito grande', description: 'A imagem deve ser menor que 10MB!' });
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = async (info: any) => {
    setLoadingBrandUrl(true);
    if (info.file.status === 'uploading') {
      const { status: status, data: response } = await BrandService.uploadImage(info.file.originFileObj);
      if (status === 201) {
        setBrandPhotoUrl(response.url);
        form.setFields([{ name: 'brandPhotoUrl', value: response.url }]);
      } else {
        console.error(response);
        notification.error({ message: 'Erro', description: 'Falha ao enviar imagem!' });
      }
    }
    setLoadingBrandUrl(false);
  };

  const handleUpload = () => {
    return brandPhotoUrl;
  };

  const uploadButton = (
    <div>
      {loadingBrandUrl ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Adicionar</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      key={brand?.brandId}
      onFinish={handleFormSubmit}
      autoComplete="off">
      <Row align="middle" justify="space-between" className="my-0">
        <Col span={24}>
          <Form.Item name="brandId" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="brandPhotoUrl" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
        </Col>

        <Col span="24" className="text-center">
          <ImgCrop rotate modalWidth="400px" aspect={1} quality={0.7} modalTitle="Redimensionar imagem" shape="round" minZoom={0.8} maxZoom={10} >
            <Upload
              name="brand"
              className="rounded"
              listType="picture-card"
              showUploadList={false}
              customRequest={handleUpload}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {brandPhotoUrl ? <Avatar className="object-fit-cover" round src={brandPhotoUrl} /> : uploadButton}
            </Upload>
          </ImgCrop>
        </Col>
        <Col span={16}>
          <Form.Item name="brandName" label="Nome da Marca" className="mb-2" rules={[{ required: true, message: 'Informe o nome da marca'}]}>
            <Input placeholder="Nome da Marca" size="large" className="input" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="brandIsActive" valuePropName="checked" label="Habilitar marca" className="mb-2">
            <Switch defaultChecked />
          </Form.Item>
        </Col>
      </Row>

      <Button className="mt-4" type="primary" htmlType="submit" size="large" block>
        Salvar
      </Button>
    </Form>
  )
}

export default React.forwardRef(BrandForm);
