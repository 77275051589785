import { useLink } from '@hooks/LinkContext';
import { Link } from '@models/Link';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Row, Col, Table, message, Modal, Button, notification, Select, Checkbox } from 'antd';
import { ILinkForm } from './models/ILinkForm';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import LinkForm from './components/LinkForm';
import { SorterResult } from 'antd/lib/table/interface';
import { FormOutlined, CopyOutlined, SearchOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import TableFilters from '@components/TableFilter';
import { LinkFilter } from '@services/link';
import moment from 'moment';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { useBrand } from '@hooks/BrandContext';
import CustomTagSelect from '@components/CustomTagSelect';

const LinkPage: React.FC = () => {
  type LinkFormHandleHandle = React.ElementRef<typeof LinkForm>;
  const { links, total, currentPage, loading, loadRequestLink, createLink, updateLink } = useLink();
  const { manufacturers: manufacturersHeadOffices, loadRequestManufacturer } = useManufacturer();
  const { brands, loadRequestBrand } = useBrand();
  const linkFormRef = useRef<LinkFormHandleHandle>(null);
  const [createLinkModalIsVisible, setCreateLinkModalIsVisible] = useState<boolean>(false);
  const [currentLink, setCurrentLink] = useState<Link>();
  const [filterLinks, setFilterLinks] = useState<{
    filters: LinkFilter;
    pagination: TablePaginationConfig;
    orderBy: string;
    direction: 'ASC' | 'DESC';
  }>(Object.assign({}));

  // Constantes
  const divisor = '_&_';
  const optionsFilterActive: {
    onlyManufacturers: 'onlyManufacturers';
    onlyRepresentatives: 'onlyRepresentatives';
    inactiveForAll: 'inactiveForAll';
    activeForAll: 'activeForAll';
  } = {
    onlyManufacturers: 'onlyManufacturers',
    onlyRepresentatives: 'onlyRepresentatives',
    inactiveForAll: 'inactiveForAll',
    activeForAll: 'activeForAll',
  };

  useEffect(() => {
    if (!createLinkModalIsVisible && linkFormRef && linkFormRef.current) {
      linkFormRef.current.resetForm();
    }
  });

  useEffect(() => {
    loadRequestManufacturer(true);
    loadRequestBrand();
  }, []);

  useEffect(() => {
    const { filters, pagination, orderBy = 'linkTitle', direction = 'ASC' } = filterLinks;
    loadRequestLink(filters, pagination?.current, pagination?.pageSize, orderBy, direction);
  }, [filterLinks]);

  const copyToClipboard = (textToCopy: string) => {
    notification.success({ message: 'URL copiada', description: 'A URL foi copiada para área de transferência' });
    copy(textToCopy);
  };

  const handleSaveLink = useCallback(
    async (link: ILinkForm) => {
      try {
        if (!link.linkId) {
          await createLink(link);
          message.success('Link cadastrado com sucesso', 2);
        } else {
          await updateLink(link);
          message.success('Link atualizado com sucesso', 2);
        }
        setCreateLinkModalIsVisible(false);
      } catch (error) {
        console.error('error in handle create link: ', error);
        message.error('Falha ao salvar Link');
      } finally {
        setCurrentLink(undefined);
      }
    },
    [createLink, updateLink, linkFormRef],
  );

  const handleEditLink = (link: Link) => {
    setCurrentLink(link);
    setCreateLinkModalIsVisible(true);
  };

  const handleSelectManufacturers = (values: string[]) => {
    const manufacturerIds = values.map((element) => +element.split(divisor)[0]);
    setFilterLinks({ ...filterLinks, filters: { ...filterLinks.filters, manufacturerIds } });
  };
  const handleSelectBrands = (values: string[]) => {
    const brandIds = values.map((element) => +element.split(divisor)[0]);
    setFilterLinks({ ...filterLinks, filters: { ...filterLinks.filters, brandIds } });
  };

  const handleCheckboxFilterByActive = (active: boolean) => {
    if (active) {
      setFilterLinks({ ...filterLinks, filters: { ...filterLinks.filters, linkIsActive: true } });
    } else {
      setFilterLinks({ ...filterLinks, filters: { ...filterLinks.filters, linkIsActive: undefined } });
    }
  };

  const handleSorter = (sorter: SorterResult<Link>): { orderBy: string; direction: 'ASC' | 'DESC' } => ({
    orderBy: sorter.field?.toString() || 'linkTitle',
    direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
  });

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const { orderBy, direction } = handleSorter(sorter);
    setFilterLinks({
      filters: { ...filterLinks.filters, ...handleFilter(filters) },
      pagination,
      orderBy,
      direction,
    });
  };

  const handleFilter = (filters: any): LinkFilter => {
    let processedFilters: LinkFilter = { ...filters };
    if (filters?.linkCreatedAt) {
      processedFilters = {
        ...processedFilters,
        linkCreatedAtInitial: moment(filters.linkCreatedAt[0], 'DD/MM/YYYY').toDate(),
        linkCreatedAtFinal: moment(filters.linkCreatedAt[1], 'DD/MM/YYYY').toDate(),
      };
    }
    if (filters?.linkExpirationInitialDate) {
      processedFilters = {
        ...processedFilters,
        linkExpirationInitialDateInitial: moment(filters.linkExpirationInitialDate[0], 'DD/MM/YYYY').toDate(),
        linkExpirationInitialDateFinal: moment(filters.linkExpirationInitialDate[1], 'DD/MM/YYYY').toDate(),
      };
    }
    if (filters?.linkExpirationFinalDate) {
      processedFilters = {
        ...processedFilters,
        linkExpirationFinalDateInitial: moment(filters.linkExpirationFinalDate[0], 'DD/MM/YYYY').toDate(),
        linkExpirationFinalDateFinal: moment(filters.linkExpirationFinalDate[1], 'DD/MM/YYYY').toDate(),
      };
    }
    if (filters?.linkActiveFor) {
      switch (filters?.linkActiveFor[0]) {
        case optionsFilterActive.inactiveForAll:
          processedFilters = {
            ...processedFilters,
            linkManufacturerActive: false,
            linkRepresentativeActive: false,
          };
          break;

        case optionsFilterActive.onlyManufacturers:
          processedFilters = {
            ...processedFilters,
            linkManufacturerActive: true,
            linkRepresentativeActive: false,
          };
          break;

        case optionsFilterActive.onlyRepresentatives:
          processedFilters = {
            ...processedFilters,
            linkManufacturerActive: false,
            linkRepresentativeActive: true,
          };
          break;

        // o padrão é o mesmo que activeForAll
        default:
          processedFilters = {
            ...processedFilters,
            linkManufacturerActive: true,
            linkRepresentativeActive: true,
          };
          break;
      }
    } else {
      processedFilters = {
        ...processedFilters,
        linkManufacturerActive: undefined,
        linkRepresentativeActive: undefined,
      };
    }
    return processedFilters;
  };

  const tablefilter = (
    { setSelectedKeys, selectedKeys, confirm, clearFilters }: any,
    placeholder: string,
    type?: 'text' | 'datePickerRange',
  ) => {
    return (
      <TableFilters
        inputPlaceholder={placeholder}
        inputValue={selectedKeys}
        onChangeInputValue={setSelectedKeys}
        onFilter={confirm}
        onClearFilters={clearFilters}
        type={type}
      />
    );
  };

  const formatDate = (dateToFormat: string | number | Date) =>
    new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(dateToFormat));

  const searchOutlinedIcon = <SearchOutlined />;
  const columns: ColumnType<Link>[] = [
    {
      title: 'Título',
      dataIndex: 'linkTitle',
      sorter: true,
      filterDropdown: (props) => tablefilter(props, 'Filtrar por título'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Fabricante',
      dataIndex: 'linkManufacturerBrands',
      render: (_, { linkManufacturerBrands = [] }: Link) => {
        if (linkManufacturerBrands?.length > 0) {
          const manufacturerNames: string[] = linkManufacturerBrands.map(
            ({ manufacturerId }) =>
              manufacturersHeadOffices.find(({ integrationId: { integrationId } }) => integrationId === manufacturerId)
                ?.manufacturerFantasyName || '',
          );
          return (
            <>
              {manufacturerNames
                //remove os itens vazios
                .filter((x) => !!x)
                //remove os itens repetidos
                .filter((element: string, i) => manufacturerNames.indexOf(element) === i)
                .join(', ')}
            </>
          );
        }
        return <span title="Sem fabricantes">-</span>;
      },
      ellipsis: true,
    },
    {
      title: 'Marca',
      dataIndex: 'linkManufacturerBrands',
      render: (_, { linkManufacturerBrands = [] }: Link) => {
        if (linkManufacturerBrands?.length > 0) {
          const brandNames: string[] = linkManufacturerBrands.map(
            ({ brandId }) => brands.find((brand) => brand.brandId === brandId)?.brandName || '',
          );
          return (
            <>
              {brandNames
                //remove os itens vazios
                .filter((x) => !!x)
                //remove os itens repetidos
                .filter((element: string, i) => brandNames.indexOf(element) === i)
                .join(', ')}
            </>
          );
        }
        return <span title="Sem marcas">-</span>;
      },
      ellipsis: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'linkDescription',
      sorter: true,
      filterDropdown: (props) => tablefilter(props, 'Filtrar por descrição'),
      filterIcon: searchOutlinedIcon,
      ellipsis: true,
    },
    {
      title: 'URL',
      dataIndex: 'linkUrl',
      sorter: true,
      filterDropdown: (props) => tablefilter(props, 'Filtrar por URL'),
      filterIcon: searchOutlinedIcon,
      ellipsis: true,
    },
    {
      title: 'Data da inclusão',
      dataIndex: 'linkCreatedAt',
      sorter: true,
      render: (_: string, { linkCreatedAt }: Link): JSX.Element => {
        return <>{formatDate(linkCreatedAt)}</>;
      },
      filterDropdown: (props) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Validade inicial',
      dataIndex: 'linkExpirationInitialDate',
      sorter: true,
      render: (_: string, { linkExpirationInitialDate }: Link): JSX.Element => {
        return <>{formatDate(linkExpirationInitialDate)}</>;
      },
      filterDropdown: (props) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Validade final',
      dataIndex: 'linkExpirationFinalDate',
      sorter: true,
      render: (_: string, { linkExpirationFinalDate }: Link): JSX.Element => {
        return <>{formatDate(linkExpirationFinalDate)}</>;
      },
      filterDropdown: (props) => tablefilter(props, 'Filtrar por data da inclusão', 'datePickerRange'),
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Ativo',
      dataIndex: 'linkActiveFor',
      render: (_: string, { linkManufacturerActive, linkRepresentativeActive }: Link): JSX.Element => {
        const labels = [];
        if (linkManufacturerActive) labels.push('Fabricante');
        if (linkRepresentativeActive) labels.push('Representante');
        return <>{labels.join(', ')}</>;
      },
      filterMultiple: false,
      filters: [
        { text: 'Apenas fabricantes', value: 'onlyManufacturers' },
        { text: 'Apenas representantes', value: 'onlyRepresentatives' },
        { text: 'Inativo para todos', value: 'inactiveForAll' },
        { text: 'Ativo para todos', value: 'activeForAll' },
      ],
      filterIcon: searchOutlinedIcon,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      width: '7em',
      render: (_, link: Link): JSX.Element => {
        return (
          <>
            <Button
              type="link"
              icon={<CopyOutlined title="Copiar para área de transferência" />}
              onClick={() => copyToClipboard(link.linkUrl)}
            ></Button>
            <Button
              type="link"
              icon={<FormOutlined title="Editar link" />}
              onClick={() => handleEditLink(link)}
            ></Button>
          </>
        );
      },
    },
  ];

  const customTagRender = (props: any) => <CustomTagSelect data={props} />;

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span="8" className="d-flex justify-space-between mr-4">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            size="large"
            className="input"
            placeholder="Selecione os fabricantes"
            onChange={handleSelectManufacturers}
            options={manufacturersHeadOffices.map(
              ({ manufacturerName, manufacturerFantasyName, integrationId: { integrationId } }) => ({
                label: `${manufacturerFantasyName}`,
                value: `${integrationId}${divisor}${manufacturerFantasyName}${divisor}${manufacturerName}`,
              }),
            )}
            maxTagCount="responsive"
            tagRender={customTagRender}
            showArrow
            allowClear
          ></Select>
        </Col>
        <Col span="7" className="d-flex justify-space-between">
          <Select
            showSearch
            mode="multiple"
            style={{ width: '100%' }}
            size="large"
            className="input"
            placeholder="Selecione as marcas"
            onChange={handleSelectBrands}
            options={brands.map(({ brandId, brandName }) => ({
              label: brandName,
              value: `${brandId}${divisor}${brandName}`,
            }))}
            maxTagCount="responsive"
            tagRender={customTagRender}
            showArrow
            allowClear
          ></Select>
        </Col>
        <Col span="4" className="d-flex justify-space-around">
          <Checkbox onChange={({ target: { checked } }) => handleCheckboxFilterByActive(checked)}>
            Filtrar apenas ativos
          </Checkbox>
        </Col>
        <Col span="3" className="d-flex justify-space-between">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            onClick={() => {
              setCurrentLink(undefined);
              setCreateLinkModalIsVisible(true);
            }}
          >
            Novo Link
          </Button>
        </Col>
      </Row>
      <Table
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
        }}
        loading={loading}
        columns={columns}
        dataSource={links?.map((link: Link) => ({ ...link, key: link.linkId }))}
        onChange={handleTableChange}
      ></Table>
      <Modal
        title={currentLink ? 'Editar Link' : 'Novo Link'}
        visible={createLinkModalIsVisible}
        footer={null}
        onCancel={() => {
          setCreateLinkModalIsVisible(false);
          setCurrentLink(undefined);
        }}
      >
        <LinkForm ref={linkFormRef} onSubmit={handleSaveLink} link={currentLink} />
      </Modal>
    </>
  );
};

export default LinkPage;
