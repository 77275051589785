import { IParameterForm } from '@pages/ParameterPage/models/IParameterForm';

import * as api from './api';

export function get(params?: any) {
  return api.get('parameters', params);
}

export function save(parameter: IParameterForm): Promise<any> {
  return api.post('parameters', parameter);
}

export function update(parameter: IParameterForm) {
  return api.put(`parameters/${parameter.parameterId}`, parameter);
}
