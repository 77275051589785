import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import 'antd-css-utilities/utility.min.css';

export const themeColors: {
  primary_color: '#510c76';
  primary_color_hover: '#310966';
  secondary_color: '#eb0045';
  support_color: '#ffffff';
} = {
  primary_color: '#510c76',
  primary_color_hover: '#310966',
  secondary_color: '#eb0045',
  support_color: '#ffffff',
};

export default createGlobalStyle`
  * {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

  body {
    overflow: hidden;
  }

  :root {
    --primary-color: ${themeColors.primary_color};
    --secondary-color: ${themeColors.secondary_color};
    --support-color: ${themeColors.support_color};
  }

  .background-primary-color {
    background-color: var(--primary-color);
  }

  .overflow-auto {
    overflow: auto;
  }

  .ant-upload-select {
    border-radius: 50% !important;
  }

  .ant-btn-primary,
  .ant-btn-primary:visited,
  .ant-btn-primary::after,
  .ant-btn-primary::before,
  .ant-btn-primary:focus,
  .ant-btn-primary::selection {
    border: var(--primary-color);
    background-color: var(--primary-color);
  }

  .ant-btn-primary:hover{
    background-color: ${themeColors.primary_color_hover};
  }

  .object-fit-cover {
    object-fit: cover;
  }
`;
