import React, { useCallback, useEffect } from 'react';

import { Button, Col, Form, Input, notification, Row, Select, Spin } from 'antd';
import { states } from '@services/states';
import { useManufacturer } from '@hooks/ManufacturerContext';
import { Manufacturer } from '@models/Manufacturer';

const ManufacturerGeneralInfo: React.FC = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const {
    currentManufacturer,
    updateManufacturer,
    loading,
    setManufacturersByIntegration,
    manufacturersByIntegration,
  } = useManufacturer();

  const arrayStates: { description: string; acronym: string }[] = states('BR');

  useEffect(() => {
    form.setFieldsValue(currentManufacturer);
  }, [form, currentManufacturer]);

  const save = useCallback(async () => {
    const values = await form.validateFields();
    const manufacturer: Manufacturer = await updateManufacturer({ ...currentManufacturer, ...values });
    if (manufacturer != null) {
      setManufacturersByIntegration(
        manufacturersByIntegration.map((element) => {
          if (element.manufacturerId === manufacturer.manufacturerId) {
            return manufacturer;
          }
          return element;
        }),
      );
      notification.success({ message: 'Sucesso', description: 'Salvo com sucesso!' });
    }
  }, [updateManufacturer, currentManufacturer, manufacturersByIntegration]);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" form={form} autoComplete="off" onFinish={save}>
        <Row>
          <Col span={16}>
            <strong>Nome da Empresa: </strong>
            {currentManufacturer?.manufacturerName}
          </Col>
          <Col span={8}>
            <strong>CNPJ: </strong>
            {currentManufacturer?.manufacturerCnpj &&
              currentManufacturer?.manufacturerCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
          </Col>
          <Col span={20} className="mt-4">
            <strong>Nome Fantasia: </strong>
            {currentManufacturer?.manufacturerFantasyName}
          </Col>
          <Col span={6} className="mt-4">
            <Form.Item
              name="manufacturerUf"
              label="Estado"
              hasFeedback
              rules={[{ required: true, message: 'Selecione o estado' }]}
            >
              <Select placeholder="Selecione o estado">
                {arrayStates.map((state: any) => {
                  return (
                    <Option key={state?.acronym} value={state?.acronym}>
                      {state.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col push={1} span={12} className="text-center mt-4">
            <Form.Item
              name="manufacturerNumberStateRegistration"
              label="Número de registro estadual"
              className="mb-2"
              rules={[
                { required: true, message: 'Informe o número de registro estadual' },
                { pattern: /^\d*$/, message: 'Número de registro estadual inválido' },
              ]}
            >
              <Input placeholder="Número de registro estadual" className="input" />
            </Form.Item>
          </Col>
        </Row>
        <Button loading={loading} className="mt-4" type="primary" htmlType="submit" size="large" block>
          Salvar
        </Button>
      </Form>
    </Spin>
  );
};

export default ManufacturerGeneralInfo;
