import React from 'react';
import { Router } from 'react-router-dom';

import { ManufacturerProvider } from './hooks/ManufacturerContext';
import { BrandProvider } from '@hooks/BrandContext';
import Routes from './routes';
import history from './services/history';
import GlobalStyle from './styles/globals';
import { CategoryProvider } from '@hooks/CategoryContext';
import { ParameterProvider } from '@hooks/ParameterContext';
import { SubcategoryProvider } from '@hooks/SubcategoryContext';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, keycloakProviderInitConfig } from './utils/keycloack-auth';
import { IntegrationProvider } from '@hooks/IntegrationContext';
import { LinkProvider } from '@hooks/LinkContext';
import { IntegrationPermissionProvider } from '@hooks/IntegrationPermissionContext';

const App: React.FC = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
      <ManufacturerProvider>
        <IntegrationProvider>
          <BrandProvider>
            <CategoryProvider>
              <SubcategoryProvider>
                <ParameterProvider>
                  <LinkProvider>
                    <IntegrationPermissionProvider>
                      <Router history={history}>
                        <Routes />
                      </Router>
                    </IntegrationPermissionProvider>
                  </LinkProvider>
                  <GlobalStyle />
                </ParameterProvider>
              </SubcategoryProvider>
            </CategoryProvider>
          </BrandProvider>
        </IntegrationProvider>
      </ManufacturerProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
