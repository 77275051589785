import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col, Input, Checkbox, Table, Button, Modal, Switch, Select, notification, Popconfirm } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import { Category } from '@models/Category';
import { Subcategory } from '@models/Subcategory';
import { useSubcategory } from '@hooks/SubcategoryContext';
import { ISubcategoryForm } from './models/ISubcategoryForm';
import SubcategoryForm from './components/SubcategoryForm';
import { useCategory } from '@hooks/CategoryContext';
import { debounce } from 'lodash';

interface CategoryOption {
  label: string;
  value: any;
}

const CategoryPage: React.FC = () => {
  const {
    subcategories,
    loading,
    errors,
    clearErrors,
    loadRequestSubcategory,
    clearSubcategories,
    createSubcategory,
    updateSubcategory,
  } = useSubcategory();
  const { categories, loadRequestCategory } = useCategory();
  const [searchSubcategory, setSearchSubcategory] = useState<string>();
  const [filterSubcategoryIsActive, setFilterSubcategoryIsActive] = useState<boolean>();
  const [createSubcategoryModalIsVisible, setCreateSubcategoryModalIsVisible] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [currentSubcategory, setCurrentSubcategory] = useState<Subcategory>();

  const onCategorySearch = React.useMemo(() => {
    const loadOptions = (search: string) => {
      let filtered = categories;
      if (search) {
        filtered = categories.filter((category: Category) => {
          return category.description.toUpperCase().startsWith(search.toUpperCase());
        });
      }
      setCategoryOptions(
        filtered.map((category: Category) => ({
          label: `${category.description} - ${category.categoryId}`,
          value: category.categoryId,
        })),
      );
    };
    return debounce(loadOptions, 500);
  }, []);

  const onCategoryClear = useCallback(() => {
    clearSubcategories();
    setCurrentCategory(undefined);
    onCategorySearch('');
  }, [currentCategory]);

  const onCategorySelected = (categoryId: number) => {
    if (categoryId) {
      setCurrentCategory(categories.filter((category: Category) => category.categoryId == categoryId)[0]);
      loadRequestSubcategory(categoryId);
    }
  };

  const handleSaveSubcategory = useCallback(
    (subcategory: ISubcategoryForm) => {
      if (!subcategory.subCategoryId) {
        createSubcategory({ ...subcategory, subCategoryIsActive: true });
      } else {
        updateSubcategory(subcategory);
        setCurrentSubcategory(undefined);
      }
      setCreateSubcategoryModalIsVisible(false);
      notification.success({ message: 'Sucesso', description: 'Subcategoria cadastrado com sucesso' });
    },
    [createSubcategory, updateSubcategory],
  );

  const handleEditSubcategory = (subcategory: Subcategory | undefined) => {
    setCurrentSubcategory(subcategory);
    if (subcategory) setCreateSubcategoryModalIsVisible(true);
    else setCreateSubcategoryModalIsVisible(false);
  };

  const handleToggleSubCategory = useCallback(
    (subcategory: ISubcategoryForm) => {
      subcategory.subCategoryIsActive = !subcategory.subCategoryIsActive;
      try {
        updateSubcategory(subcategory);
      } catch (error) {
        notification.error({ message: 'Erro', description: 'Falha ao editar status da subcategoria.' });
      }
    },
    [updateSubcategory],
  );

  useEffect(() => {
    loadRequestCategory();
    loadRequestSubcategory(currentCategory?.categoryId);
  }, []);

  useEffect(() => {
    if (errors) {
      errors.forEach((errorMessage: string) => {
        notification.error({
          message: 'Erro',
          description: errorMessage,
          onClose: () => {
            clearErrors();
          },
        });
      });
    }
  }, [errors]);

  useEffect(() => {
    setCategoryOptions(
      categories.map((category: Category) => ({
        label: `${category.description} - ${category.categoryId}`,
        value: category.categoryId,
      })),
    );
  }, [categories]);

  useEffect(() => {
    if (searchSubcategory !== undefined || filterSubcategoryIsActive !== undefined) {
      loadRequestSubcategory(
        currentCategory?.categoryId,
        filterSubcategoryIsActive ? true : undefined,
        searchSubcategory,
      );
    }
  }, [searchSubcategory, filterSubcategoryIsActive]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'subCategoryId',
      sorter: (subcategory: Subcategory, nextSubcategory: Subcategory) =>
        subcategory.subCategoryId - nextSubcategory.subCategoryId,
    },
    {
      title: 'Subcategoria',
      dataIndex: 'description',
      sorter: (subcategory: Subcategory, nextSubcategory: Subcategory) =>
        subcategory.description.localeCompare(nextSubcategory.description),
    },
    {
      title: 'Criado em',
      dataIndex: 'subCategoryCreatedAt',
      render: (key: string, subcategory: Subcategory): JSX.Element => {
        return (
          <>
            {new Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            }).format(new Date(subcategory.subCategoryCreatedAt))}
          </>
        );
      },
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (text: string, subcategory: any): JSX.Element => {
        const { key, ...selectedSubCategory } = subcategory;

        return (
          <>
            <Button
              onClick={() => handleEditSubcategory(selectedSubCategory)}
              type="link"
              icon={<FormOutlined />}
            ></Button>
            <Popconfirm
              title={`Tem certeza que deseja ${
                selectedSubCategory.subCategoryIsActive ? 'desativar' : 'ativar'
              } a subcategoria ${selectedSubCategory.description}?`}
              onConfirm={() => handleToggleSubCategory(selectedSubCategory)}
              okText="Sim"
              cancelText="Não"
            >
              <Switch checked={selectedSubCategory.subCategoryIsActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span="12" className="d-flex justify-space-between">
          <Select
            showSearch
            size="large"
            className="input"
            allowClear={true}
            style={{ width: '100%' }}
            placeholder="Selecione uma categoria"
            optionFilterProp="description"
            filterOption={false}
            onSearch={onCategorySearch}
            onChange={onCategorySelected}
            onClear={onCategoryClear}
            options={categoryOptions}
          />
        </Col>
      </Row>
      <Row className="my-8" align="middle">
        <Col span="12" className="d-flex justify-space-between">
          <Input.Search
            allowClear
            disabled={!currentCategory}
            onSearch={(search) => setSearchSubcategory(search)}
            placeholder="Busca por nome da subcategoria"
            size="large"
            className="input mr-9"
          />
        </Col>
        <Col>
          <Checkbox
            disabled={!currentCategory}
            onChange={(checkBoxChangeEvent) => setFilterSubcategoryIsActive(checkBoxChangeEvent.target.checked)}
          >
            Filtrar apenas ativos
          </Checkbox>
          <Button
            disabled={!currentCategory}
            onClick={() => setCreateSubcategoryModalIsVisible(true)}
            size="large"
            type="primary"
            className="ml-4"
          >
            Nova Subcategoria
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={loading}
        dataSource={subcategories?.map((subcategory: Subcategory) => ({
          ...subcategory,
          key: subcategory.subCategoryId,
        }))}
      />

      <Modal
        title={(currentSubcategory ? 'Editar ' : 'Nova ') + 'Subcategoria'}
        visible={createSubcategoryModalIsVisible}
        footer={null}
        destroyOnClose={true}
        onCancel={() => {
          handleEditSubcategory(undefined);
        }}
      >
        <SubcategoryForm subcategory={currentSubcategory} category={currentCategory} onSubmit={handleSaveSubcategory} />
      </Modal>
    </>
  );
};

export default CategoryPage;
