import React, { useState } from 'react';
import { Button, DatePicker, Input, Row } from 'antd';
import moment, { Moment } from 'moment';

interface TableFiltersProps {
  inputPlaceholder: string;
  inputValue: any;
  onChangeInputValue: Function;
  onFilter: Function;
  onClearFilters: Function;
  type?: 'text' | 'datePickerRange';
}

const { RangePicker } = DatePicker;

const TableFilters: React.FC<TableFiltersProps> = ({
  inputValue,
  onChangeInputValue,
  onFilter,
  onClearFilters,
  inputPlaceholder,
  type = 'text',
}) => {
  const [inputValueDatePicker, setInputValueDatePicker] = useState<[Moment, Moment]>();
  const style: React.CSSProperties =
    type === 'datePickerRange' ? { width: '250px', padding: '15px' } : { width: '200px', padding: '20px' };
  return (
    <Row className="d-flex" align="middle" justify="center" style={style}>
      {type == 'datePickerRange' ? (
        <RangePicker
          className="w-100"
          format="DD/MM/YYYY"
          onCalendarChange={(_, [initialDate, finalDate]) => {
            onChangeInputValue([initialDate, finalDate]);
            setInputValueDatePicker([moment(initialDate, 'DD/MM/YYYY'), moment(finalDate, 'DD/MM/YYYY')]);
          }}
          value={inputValueDatePicker}
          autoFocus
        />
      ) : (
        <Input
          autoFocus
          placeholder={inputPlaceholder}
          value={inputValue[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeInputValue(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => onFilter()}
        />
      )}
      <Button
        className="mt-2 mr-1"
        onClick={() => {
          onClearFilters();
          setInputValueDatePicker(undefined);
        }}
      >
        Limpar
      </Button>
      <Button className="mt-2 ml-1" type="primary" onClick={() => onFilter()}>
        Filtrar
      </Button>
    </Row>
  );
};

export default TableFilters;
