import { Manufacturer } from '@models/Manufacturer';
import { Response } from '@models/Response';
import { IManufacturerForm } from '@pages/ManufacturerPage/models/IManufacturerForm';
import * as api from './api';

const end_point = 'manufacturers';

export function get(onlyHeadOffices: boolean = false): Promise<Response<Manufacturer>> {
  if (onlyHeadOffices) {
    return api.get([end_point, 'allHeadoffice'].join('/'));
  }
  return api.get(end_point);
}

export function save(manufacturer: IManufacturerForm): Promise<any> {
  return api.post(end_point, { manufacturer }).catch((error) => {
    console.log('no save', error, error.response);
    return Promise.resolve(error.response);
  });
}

export function update(manufacturer: IManufacturerForm): Promise<any> {
  return api.put([end_point, manufacturer.manufacturerId].join('/'), manufacturer).catch((error) => {
    console.log('no update', error, error.response);
    return Promise.resolve(error.response);
  });
}

export function getByIntegration(integrationId: number): Promise<Response<Manufacturer>> | Promise<any> {
  return api.get([end_point, 'integration', integrationId.toString()].join('/'));
}

export function getAddressByManufacturerId(manufacturerId: number): Promise<any> {
  return api.get([end_point, manufacturerId.toString(), 'addresses'].join('/'));
}
