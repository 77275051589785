import React from 'react';

import { Col, Row } from 'antd';

import { Parameter } from '@models/Parameter';

interface ParameterDetailsProps {
  parameter?: Parameter
}

const ParameterDetails: React.FC<ParameterDetailsProps> = ({ parameter }) => {
  return (
    <Row align="middle" justify="space-between" className="my-0">
      <Col span={12}>
        <strong>Id do Parâmetro:</strong> {parameter?.parameterId}
      </Col>
      <Col span={12}>
        <strong>Ativo:</strong> {parameter?.parameterIsActive ? 'Sim' : 'Não'}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Código do Parâmetro:</strong> {parameter?.code}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Descrição do Parâmetro:</strong> <br /> {parameter?.description}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Descrição da Utilidade:</strong> <br /> {parameter?.utility}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Descrição da Funcionalidade:</strong> <br /> {parameter?.functionality}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Descrição da Variante:</strong> <br /> {parameter?.variant}
      </Col>
      <Col className="mt-3" span={24}>
        <strong>Descrição do Valor:</strong> <br /> {parameter?.value}
      </Col>
    </Row>
  )
}

export default ParameterDetails;
