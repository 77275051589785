import React from 'react';

import { useKeycloak } from '@react-keycloak/web';
import Loading from '@components/Loading';

const LoginPage: React.FC = () => {
  const { keycloak } = useKeycloak();

  keycloak?.login();
  return <Loading />

};

export default LoginPage;
