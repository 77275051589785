import { Category } from '@models/Category';
import { Response } from '@models/Response';
import { ICategoryForm } from '@pages/CategoryPage/models/ICategoryForm';
import * as api from './api';

export function get(status: boolean, search: string = ''): Promise<Response<Category>> {
  let url: string = 'categories';
  if (search.length > 0) {
    url += `?description=${search}`;
  }
  if (status) {
    url += url.includes('?') ? '&' : '?';
    url += 'categoryIsActive=true';
  }
  return api.get(url);
}

export function save(category: ICategoryForm): Promise<any> {
  return api.post('categories', category);
}

export function update(category: ICategoryForm) {
  return api.put(`categories/${category.categoryId}`, category);
}
