import { Brand } from '@models/Brand';
import { Response } from '@models/Response';
import { IBrandForm } from '@pages/BrandPage/models/IBrandForm';
import * as api from './api';

export function get(status: boolean, search: string = ''): Promise<Response<Brand>> {
  let url: string = 'brands';
  if (search.length > 0) {
    url += `?name=${search}`;
  }
  if (status) {
    url += url.includes('?') ? '&' : '?';
    url += 'brandIsActive=true';
  }

  return api.get(url);
}

export function save(brand: IBrandForm): Promise<any> {
  return api.post('brands', brand);
}

export function update(brand: IBrandForm) {
  return api.put(`brands/${brand.brandId}`, brand);
}

export function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return api.postWithConfig('upload', formData, config).catch((error) => {
    return Promise.resolve(error.response);
  });
}
