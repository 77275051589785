import { AxiosResponse } from 'axios';

import * as api from './api';

const end_point = 'integration-permission';

export function getPermissions(): Promise<AxiosResponse<any>> {
  return api.get([end_point, 'permissions'].join('/'));
}

export function getPermissionsByIntegrationId(integrationId: number): Promise<AxiosResponse<any>> {
  return api.get([end_point, integrationId].join('/'));
}

export function updatePermissionsByIntegrationId(
  integrationId: number,
  permissions: string[],
): Promise<AxiosResponse<any>> {
  return api.put([end_point, integrationId].join('/'), {
    permissions,
  });
}
