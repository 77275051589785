import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Spin } from 'antd';

import { IIntegrationForm } from './models/IIntegrationForm';
import { useIntegration } from '@hooks/IntegrationContext';

interface ManufacturerIntegrationProps {
  onSubmit: Function;
}

const ManufacturerIntegration: React.FC<ManufacturerIntegrationProps> = ({ onSubmit }) => {
  const { loading, currentIntegration } = useIntegration();
  const [form] = Form.useForm();
  const [typesIntegrations] = useState<any[]>([{ id: 1, name: 'TOTVS Modas' }]);
  const [typesAuthentications] = useState<any[]>([{ id: 1, name: '1' }]);
  const [intervals] = useState([15, 30, 45, 60]);
  const { Option } = Select;
  const [regexUrl] = useState<RegExp>(/(https?):\/\/.{2,50}\..{2,400}[\w\-]$/i);

  const handleFormSubmit = async () => {
    const values: IIntegrationForm = await form.validateFields();
    const success = await onSubmit(values);
    if (success) form.resetFields();
  };

  useEffect(() => {
    if (currentIntegration != null) {
      form.setFields([
        { name: 'integrationId', value: currentIntegration.integrationId },
        { name: 'integrationName', value: currentIntegration.integrationName },
        { name: 'integrationSyncInterval', value: currentIntegration.integrationSyncInterval },
        { name: 'integrationSystem', value: currentIntegration.integrationSystem },
        { name: 'integrationAuthSystem', value: currentIntegration.integrationAuthSystem },
        { name: 'integrationUrl', value: currentIntegration.integrationUrl },
        { name: 'integrationGrantType', value: currentIntegration.integrationGrantType },
        { name: 'integrationClientId', value: currentIntegration.integrationClientId },
        { name: 'integrationUsername', value: currentIntegration.integrationUsername },
        { name: 'integrationClientSecret', value: currentIntegration.integrationClientSecret },
        { name: 'integrationPassword', value: currentIntegration.integrationPassword },
        { name: 'integrationContactName', value: currentIntegration.integrationContactName },
        { name: 'integrationEmail', value: currentIntegration.integrationEmail },
        { name: 'integrationPhone', value: currentIntegration.integrationPhone },
      ]);
    } else {
      form.resetFields();
    }
  }, [currentIntegration]);

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFormSubmit}
        autoComplete="off"
        initialValues={{
          integrationId: null,
          integrationSystem: 1,
          integrationAuthSystem: 1,
          integrationSyncInterval: 15,
        }}
      >
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item name="integrationContactName" label="Nome do contato" className="mb-2 mr-5">
              <Input placeholder="Informe um nome" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationEmail"
              label="Email"
              className="mb-2 mr-5"
              rules={[
                {
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Informe um email" className="input" type="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationPhone"
              label="Telefone"
              className="mb-2 mr-5"
              rules={[{ pattern: /^\d*$/, message: 'Digite apenas números' }]}
            >
              <Input placeholder="Informe um telefone" className="input" maxLength={11} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item className="d-none" name="integrationId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="integrationSystem"
              label="Sistema de integração"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o sistema de integração' }]}
            >
              <Select placeholder="Selecione o sistema de integração">
                {typesIntegrations.map((typeIntegration) => (
                  <Option key={typeIntegration.id} value={typeIntegration.id}>
                    {typeIntegration.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationAuthSystem"
              label="Sistema de autenticação"
              className="mb-2"
              rules={[{ required: true, message: 'Informe o sistema de autenticação' }]}
            >
              <Select placeholder="Selecione o sistema de autenticação">
                {typesAuthentications.map((typeAuth) => (
                  <Option key={typeAuth.id} value={typeAuth.id}>
                    {typeAuth.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationName"
              label="Nome"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o nome' }]}
            >
              <Input placeholder="Informe o nome" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationSyncInterval"
              label="Intervalo de sincronização"
              className="mb-2"
              rules={[{ required: true, message: 'Informe um intervalo' }]}
            >
              <Select placeholder="Selecione um intervalo">
                {intervals.map((interval, index) => (
                  <Option key={index} value={interval}>
                    {interval}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationUrl"
              label="URL"
              className="mb-2 mr-5"
              rules={[
                { required: true, message: 'Informe a URL', whitespace: false },
                {
                  pattern: regexUrl,
                  message: 'URL inválida! Verifique se não há espaços ou barra no fim',
                },
              ]}
            >
              <Input placeholder="Informe a URL" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationGrantType"
              label="Grant type"
              className="mb-2"
              rules={[{ required: true, message: 'Informe o grant type' }]}
            >
              <Input placeholder="Informe o grant type" className="input" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationClientId"
              label="Id do cliente"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o id do cliente' }]}
            >
              <Input placeholder="Informe a id do cliente" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationUsername"
              label="Nome de usuário"
              className="mb-2"
              rules={[{ required: true, message: 'Informe o nome de usuário' }]}
            >
              <Input placeholder="Informe o nome de usuário" className="input" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" justify="space-between" className="my-0">
          <Col span={12}>
            <Form.Item
              name="integrationClientSecret"
              label="Client_Secret"
              className="mb-2 mr-5"
              rules={[{ required: true, message: 'Informe o Client Secret' }]}
            >
              <Input placeholder="Informe o Client Secret" className="input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="integrationPassword"
              label="Senha"
              className="mb-2"
              rules={[{ required: true, message: 'Informe a senha' }]}
            >
              <Input placeholder="Informe a senha" type="password" className="input" />
            </Form.Item>
          </Col>
        </Row>
        <Button loading={loading} className="mt-4" type="primary" htmlType="submit" size="large" block>
          Salvar
        </Button>
      </Form>
    </Spin>
  );
};

export default ManufacturerIntegration;
