import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Row,
  Col,
  Input,
  Checkbox,
  Table,
  Button,
  Switch,
  Modal,
  message,
  notification,
  Popconfirm,
} from 'antd';
import Avatar from 'react-avatar';

import { FormOutlined } from '@ant-design/icons';
import { useBrand } from '@hooks/BrandContext';
import { Brand } from '@models/Brand';
import BrandForm from './components/BrandForm';
import { IBrandForm } from './models/IBrandForm';

const BrandPage: React.FC = () => {
  type BrandFormHandleHandle = React.ElementRef<typeof BrandForm>;
  const brandFormRef = useRef<BrandFormHandleHandle>(null);
  const { brands, loading, loadRequestBrand, createBrand, updateBrand } = useBrand();
  const [filterBrandIsActive, setFilterBrandIsActive] = useState<boolean>();
  const [searchBrand, setSearchBrand] = useState<string>();
  const [createBrandModalIsVisible, setCreateBrandModalIsVisible] = useState<boolean>(false);
  const [currentBrand, setCurrentBrand] = useState<Brand>();

  const handleSaveBrand = useCallback(
    (brand: IBrandForm) => {
      try {
        if (!brand.brandId) {
          createBrand({ ...brand, brandIsActive: !!brand.brandIsActive });
          message.success('Marca cadastrada com sucesso', 2);
        } else {
          updateBrand(brand);
          setCurrentBrand(undefined);
          message.success('Marca atualizada com sucesso', 2);
        }
        setCreateBrandModalIsVisible(false);
      } catch (error) {
        console.error('error in handle create brand: ', error);
        message.error('Falha ao salvar marca');
      }
    },
    [createBrand, updateBrand, brandFormRef],
  );

  const handleEditBrand = (brand: Brand) => {
    setCurrentBrand(brand);
    setCreateBrandModalIsVisible(true);
  };

  const handleToggleBrand = useCallback(
    (brand: Brand) => {
      brand.brandIsActive = !brand.brandIsActive;
      try {
        updateBrand(brand);
      } catch (error) {
        notification.error({ message: 'Erro', description: 'Falha ao salvar marca' });
      }
    },
    [updateBrand],
  );

  useEffect(() => {
    loadRequestBrand();
  }, []);

  useEffect(() => {
    if (!createBrandModalIsVisible && brandFormRef && brandFormRef.current) {
      brandFormRef.current.resetForm();
    }
  }, [createBrandModalIsVisible, brandFormRef]);

  useEffect(() => {
    if (searchBrand !== undefined || filterBrandIsActive !== undefined) {
      loadRequestBrand(filterBrandIsActive ? true : undefined, searchBrand);
    }
  }, [searchBrand, filterBrandIsActive]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'brandId',
      sorter: (brand: Brand, nextBrand: Brand) => brand.brandId - nextBrand.brandId,
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
      sorter: (brand: Brand, nextBrand: Brand) => brand.brandName.localeCompare(nextBrand.brandName),
      render: (text: string, record: any): JSX.Element => {
        return (
          <div className="d-flex align-center">
            <Avatar size="30" round="100%" name={record.brandName} src={record.brandPhotoUrl} />
            <p className="ml-4 my-0">{record.brandName}</p>
          </div>
        );
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'brandCreatedAt',
      render: (key: string, brand: Brand): JSX.Element => {
        return (
          <>
            {new Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            }).format(new Date(brand.brandCreatedAt))}
          </>
        );
      },
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (text: any, brand: any): JSX.Element => {
        const { key, ...selectedBrand } = brand;
        return (
          <>
            <Button onClick={() => handleEditBrand(brand)} type="link" icon={<FormOutlined />}></Button>
            <Popconfirm
              title={`Tem certeza que deseja ${selectedBrand.brandIsActive ? 'desativar' : 'ativar'} a marca ${
                selectedBrand.brandId
              }?`}
              onConfirm={() => handleToggleBrand(selectedBrand)}
              okText="Sim"
              cancelText="Não"
            >
              <Switch checked={selectedBrand.brandIsActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span="16" className="d-flex justify-space-between">
          <Input.Search
            allowClear
            onSearch={(search) => setSearchBrand(search)}
            placeholder="Busca por nome da marca"
            size="large"
            className="input mr-9"
          />
        </Col>
        <Col>
          <Checkbox onChange={(checkBoxChangeEvent) => setFilterBrandIsActive(checkBoxChangeEvent.target.checked)}>
            Filtrar apenas ativos
          </Checkbox>
          <Button size="large" type="primary" className="ml-4" onClick={() => setCreateBrandModalIsVisible(true)}>
            Nova Marca
          </Button>
        </Col>
      </Row>
      <Table
        pagination={{ pageSize: 5 }}
        loading={loading}
        columns={columns}
        dataSource={brands?.map((brand: Brand) => ({ ...brand, key: brand.brandId }))}
      />
      <Modal
        title={currentBrand ? 'Editar Marca' : 'Nova Marca'}
        visible={createBrandModalIsVisible}
        footer={null}
        onCancel={() => {
          setCreateBrandModalIsVisible(false);
          setCurrentBrand(undefined);
        }}
      >
        <BrandForm ref={brandFormRef} onSubmit={handleSaveBrand} brand={currentBrand} />
      </Modal>
    </>
  );
};

export default BrandPage;
