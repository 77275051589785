import { Layout } from 'antd';

import styled from 'styled-components';

const { Header: HeaderAntd, Content: ContentAntd, Footer: FooterAntd } = Layout;

export const Container = styled(Layout)`
  height: 100vh;
`;

export const Header = styled(HeaderAntd)`
  background: white;
  box-shadow: 2px 2px 5px #f1f1f1;
  z-index: 3;
  span {
    font-size: 1.1rem;
  }
`;

export const Content = styled(ContentAntd)`
  background: white;
  z-index: 2;
`;

export const Footer = styled(FooterAntd)`
  background: white;
`;