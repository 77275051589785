import { createContext, ReactNode, useContext, useState } from 'react';
import * as IntegrationPermissionService from '@services/integration-permission';
import { responseIsSuccess } from '@services/api';

export type IntegrationPermissionsType = { permissionId: string; label: string; description: string };

interface IIntegrationPermissionForm {
  integrationId: number;
  permissions: string[];
}

interface IntegrationPermissionState {
  permissions: IntegrationPermissionsType[];
  getPermissions: () => Promise<void>;
  updatePermissions: (integrationPermissionsForm: IIntegrationPermissionForm) => Promise<boolean>;
  loading: boolean;
}

interface IntegrationPermissionProviderProps {
  children: ReactNode;
}

export const IntegrationPermissionContext = createContext<IntegrationPermissionState>(Object.assign({}));

const IntegrationPermissionProvider: React.FC<IntegrationPermissionProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState([]);

  const getPermissions = async () => {
    setLoading(true);
    const { data: permissionsResult } = await IntegrationPermissionService.getPermissions().finally(() =>
      setLoading(false),
    );
    setPermissions(permissionsResult);
  };

  const updatePermissions = async ({ integrationId, permissions }: IIntegrationPermissionForm) => {
    try {
      setLoading(true);
      const { status: status } = await IntegrationPermissionService.updatePermissionsByIntegrationId(
        integrationId,
        permissions,
      );
      if (!responseIsSuccess(status)) {
        return false;
      }
      return true;
    } catch (error) {
      console.error('error in context: ', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const integrationPermissionContextProviderValue = {
    loading,
    permissions,
    getPermissions,
    updatePermissions,
  };

  return (
    <IntegrationPermissionContext.Provider value={integrationPermissionContextProviderValue}>
      {children}
    </IntegrationPermissionContext.Provider>
  );
};

const useIntegrationPermission = () => {
  return useContext(IntegrationPermissionContext);
};

export { IntegrationPermissionProvider, useIntegrationPermission };
